<nav class="navbar fixed-top" style="background: {{templateService.templateColors['TOP_MENU']?.bgColor}}">
  <div class="d-flex align-items-center navbar-left">
    <a (click)="menuButtonClick($event, sidebar.menuClickCount, sidebar.containerClassnames); false;" href="javascript:;"  class="menu-button d-none d-md-block" style="color: {{templateService.templateColors['TOP_MENU']?.textColor}}" [ngStyle]="{'pointer-events': !isTherapistUser() ? 'none' : '', 'cursor': !isTherapistUser() ? 'default' : ''}">
      {{ 'MENU_NAME' | translate }}
    <!--   <svg class="main" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 9 17">
        <rect x="0.48" y="0.5" width="7" height="1" />
        <rect x="0.48" y="7.5" width="7" height="1" />
        <rect x="0.48" y="15.5" width="7" height="1" />
      </svg> {{" "}}
      <svg class="sub" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 17">
        <rect x="1.56" y="0.5" width="16" height="1" />
        <rect x="1.56" y="7.5" width="16" height="1" />
        <rect x="1.56" y="15.5" width="16" height="1" />
      </svg> -->
    </a>

    <a (click)="mobileMenuButtonClick($event, sidebar.containerClassnames)" href="javascript:;" class="menu-button-mobile d-xs-block d-sm-block d-md-none" [ngStyle]="{'pointer-events': !isTherapistUser() ? 'none' : '', 'cursor': !isTherapistUser() ? 'default' : ''}">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26 17">
        <rect x="0.5" y="0.5" width="25" height="1" />
        <rect x="0.5" y="7.5" width="25" height="1" />
        <rect x="0.5" y="15.5" width="25" height="1" />
      </svg>
    </a>

    <div style="display: none !important;" class="search" (click)="searchAreaClick($event)">
      <input placeholder="Search..." [(ngModel)]="searchKey" (keyup)="searchKeyUp($event)">
      <span class="search-icon" (click)="searchClick($event)">
        <i class="simple-icon-magnifier"></i>
      </span>
    </div>

    <div class="btn-group ml-2" dropdown *ngIf="!isSingleLang">
      <button dropdownToggle type="button" class="language-button dropdown-toggle btn btn-light btn-sm" style="color: {{templateService.templateColors['TOP_MENU']?.textColor}}; background: {{templateService.templateColors['TOP_MENU']?.bgColor}};border: 1px solid {{templateService.templateColors['TOP_MENU']?.textColor}};">
        <img class="img-flag" src="assets/flag/{{currentLanguage}}.png" />{{currentLanguage | uppercase}} <span class="caret"></span>
      </button>
      <ul *dropdownMenu class="dropdown-menu" role="menu">
        <li role="menuitem" *ngFor="let item of languages" (click)="onLanguageChange(item)"><a class="dropdown-item" href="javascript:;"><img class="img-flag" src="assets/flag/{{item.shorthand}}.png" />{{item.label}}</a></li>
      </ul>
      <div *ngIf="treeHover && (timerInterval || fromClient)" id="tree_tooltip" class="invalid-tooltip">
        <i (click)="closeTreeHover()" class="fa fa-times-circle" style="font-size: 19px;float: right;cursor: pointer;"></i>
        {{ 'TREE_HOVER_TXT_1' | translate }}<br/>{{ 'TREE_HOVER_TXT_2' | translate }}
      </div>
    </div>
    <img style="max-height: 42px;margin-left: 20px;margin-top: -5px;" src="assets/img/icons/{{isTimerActive?'tree-active':'tree-nonactive'}}.svg" /><span style="font-weight: bold;margin-top: -11px;" [ngStyle]="{'margin-left': noOfTrees>9 ? '-26px' : '-22px'}">{{noOfTrees}}</span>
    
    <button placement="bottom" disabled class="btn btn-secondary mb-1" [ngStyle]="{'background-color': timerInterval ? '#80D163' : '#C3C3C3', 'border-color': timerInterval ? '#80D163' : '#C3C3C3'}" style="font-size: 16px;max-height: 40px;margin-right: 10px;margin-left: 20px;opacity: 1;border-radius: 0;font-weight: 600;color: #000;border-radius: 18px;padding-left: 12px;padding-right: 12px;" *ngIf="timerMinutes || timerMinutes == 0">
      {{timerHour}}:{{timerMin}}
    </button>

    <a style="display: none !important;" class="btn btn-sm btn-outline-primary ml-2 d-none d-xl-inline-block" href="javascript:;">&nbsp;BUY&nbsp;</a>
  </div>


  <a class="navbar-logo" href="/">
    <span class="logo d-none d-xs-block"></span>
    <span class="logo-mobile d-block d-xs-none"></span>
  </a>

  <div class="navbar-right">
    <div class="header-icons d-inline-block align-middle">

      <div style="display: none !important;" class="d-none d-md-inline-block align-text-bottom mr-1">
        <div class="custom-control custom-switch" tooltip="Dark Mode" placement="left">
          <input type="checkbox" class="custom-control-input" id="darkModeSwitch" [checked]="isDarkModeActive" (change)="onDarkModeChange($event)">
          <label class="custom-control-label" for="darkModeSwitch"></label>
        </div>
      </div>

      <div class="position-relative d-none d-sm-inline-block">
        <div dropdown>
          <button type="button" class="header-icon btn btn-empty" (click)="clickToOpenErrorMenu()" [disabled]="smileDisabled" style="color: {{templateService.templateColors['TOP_MENU']?.textColor}}">
            <i class="fa fa-smile-o fa-2" aria-hidden="true" style="font-size: 1.5em !important;" (mouseenter) ="smileHover=true" (mouseout)="smileHover=false"></i>
          </button>
          <div id="hov_tooltip" *ngIf="!smileDisabled && smileHover" class="invalid-tooltip">{{ 'ERROR_FORM.HOVER_MSG' | translate }}</div>
          <app-bug-reporting (closeMenu)="hideErrorMenu($event)"></app-bug-reporting>
        </div>
      </div>

      <div style="display: none !important;" class="position-relative d-inline-block">

        <div dropdown>
          <button id="button-basic" dropdownToggle type="button" id="notificationButton" class="header-icon btn btn-empty">
            <i class="simple-icon-bell"></i>
            <span class="count">3</span>
          </button>
          <div id="dropdown-basic" *dropdownMenu class="dropdown-menu dropdown-menu-right mt-3" id="notificationDropdown">
            <perfect-scrollbar>
              <div class="d-flex flex-row mb-3 pb-3 border-bottom">
                <a href="javascript:;">
                  <img src="/assets/img/profile-pic-l-2.jpg" alt="Notification Image"
                    class="img-thumbnail list-thumbnail xsmall border-0 rounded-circle" />
                </a>
                <div class="pl-3">
                  <a href="javascript:;">
                    <p class="font-weight-medium mb-1">Joisse Kaycee just sent a new comment!</p>
                    <p class="text-muted mb-0 text-small">09.04.2018 - 12:45</p>
                  </a>
                </div>
              </div>
              <div class="d-flex flex-row mb-3 pb-3 border-bottom">
                <a href="javascript:;">
                  <img src="/assets/img/notification-thumb.jpg" alt="Notification Image"
                    class="img-thumbnail list-thumbnail xsmall border-0 rounded-circle" />
                </a>
                <div class="pl-3">
                  <a href="javascript:;">
                    <p class="font-weight-medium mb-1">1 item is out of stock!</p>
                    <p class="text-muted mb-0 text-small">09.04.2018 - 12:45</p>
                  </a>
                </div>
              </div>
              <div class="d-flex flex-row mb-3 pb-3 border-bottom">
                <a href="javascript:;">
                  <img src="/assets/img/notification-thumb-2.jpg" alt="Notification Image"
                    class="img-thumbnail list-thumbnail xsmall border-0 rounded-circle" />
                </a>
                <div class="pl-3">
                  <a href="javascript:;">
                    <p class="font-weight-medium mb-1">New order received! It is total $147,20.</p>
                    <p class="text-muted mb-0 text-small">09.04.2018 - 12:45</p>
                  </a>
                </div>
              </div>
              <div class="d-flex flex-row mb-3 pb-3 ">
                <a href="javascript:;">
                  <img src="/assets/img/notification-thumb-3.jpg" alt="Notification Image"
                    class="img-thumbnail list-thumbnail xsmall border-0 rounded-circle" />
                </a>
                <div class="pl-3">
                  <a href="javascript:;">
                    <p class="font-weight-medium mb-1">3 items just added to wish list by a user!
                    </p>
                    <p class="text-muted mb-0 text-small">09.04.2018 - 12:45</p>
                  </a>
                </div>
              </div>
            </perfect-scrollbar>
          </div>
        </div>
      </div>

      <button class="header-icon btn btn-empty d-none d-sm-inline-block" type="button" id="fullScreenButton" (click)="fullScreenClick()" style="color: {{templateService.templateColors['TOP_MENU']?.textColor}}">
        <i *ngIf="!isFullScreen" class="simple-icon-size-fullscreen"></i>
        <i *ngIf="isFullScreen" class="simple-icon-size-actual"></i>
      </button>

    </div>

    <div class="user d-inline-block" dropdown>
      <button class="btn btn-empty p-0" type="button" dropdownToggle>
        <span class="name" style="color: {{templateService.templateColors['TOP_MENU']?.textColor}}">{{displayName}}</span>
        <span>
          <img *ngIf="!dataImage" alt="Profile Picture" src="/assets/img/profile.jpg" />
          <img *ngIf="dataImage" [src]="dataImage" />
        </span>
      </button>

      <div class="dropdown-menu dropdown-menu-right mt-3" *dropdownMenu role="menu">
        <a style="display: none;" class="dropdown-item c-pointer">Account</a>
        <a style="display: none;" class="dropdown-item c-pointer">Features</a>
        <a style="display: none;" class="dropdown-item c-pointer">History</a>
        <a style="display: none;" class="dropdown-item c-pointer">Support</a>
        <li style="display: none;" class="divider dropdown-divider"></li>
        <a class="dropdown-item c-pointer" (click)="onSignOut()">{{ 'LOGOUT' | translate }}</a>
      </div>
    </div>

  </div>
</nav>
<ng-template #template>
  <div class="modal-header">
    <h4 class="modal-title pull-left">{{'MODAL.NOTICE' | translate}}</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row justify-content-center">
      {{'MODAL.TIMER_STAYS' | translate}}
    </div>
    <div class="row justify-content-center">
      <button class="btn btn-primary" (click)="closeModal()">{{ 'MODAL.OK' | translate }}</button>
    </div>

  </div>
</ng-template>

<ng-template #timeoutWarning>
  <div class="modal-header">
    <h4 class="modal-title pull-left">{{'MODAL.NOTICE' | translate}}</h4>
  </div>
  <div class="modal-body">
    <div class="row justify-content-center" style="text-align:center;">
      {{'MODAL.WILL_BE_TIMED_OUT' | translate}}
    </div>
    <div class="row justify-content-center" style="margin-top:10px;margin-bottom:10px;text-align:center;">
      {{'MODAL.WILL_BE_TIMED_OUT_BEFORE_TIMER' | translate}} <b style="margin:0 1em 0 1em">{{modalTimerMins}}:{{modalTimerSeconds}}</b> {{'MODAL.WILL_BE_TIMED_OUT_AFTER_TIMER' | translate}}
    </div>
    <div class="row justify-content-center">
      <button class="btn btn-primary" (click)="closeTimeoutWarningModal()">{{ 'MODAL.HERE' | translate }}</button>
    </div>

  </div>
</ng-template>

<ng-template #timedOut>
  <div class="modal-header">
    <h4 class="modal-title pull-left">{{'MODAL.NOTICE' | translate}}</h4>
  </div>
  <div class="modal-body">
    <div class="row justify-content-center">
      {{'MODAL.TIMED_OUT' | translate}}
    </div>
    <div class="row justify-content-center">
      <button class="btn btn-primary" (click)="closeTimeoutWarningModal()">{{ 'MODAL.OK' | translate }}</button>
    </div>

  </div>
</ng-template>
