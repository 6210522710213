import { Component, OnInit, Output, EventEmitter} from '@angular/core';
import { LoggerService } from 'src/app/shared/services/logger.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DeviceDetectorService } from 'ngx-device-detector';
const html2canvas = require('html2canvas');

@Component({
  selector: 'app-bug-reporting',
  templateUrl: './bug-reporting.component.html',
  styleUrls: ['./bug-reporting.component.scss']
})
export class BugReportingComponent implements OnInit {

  isErrorMenuOpen = false;
  errorForm: FormGroup;
  showSuccess = false;
  submitting = false;

  @Output() closeMenu = new EventEmitter();

  constructor(private loggerService: LoggerService, public deviceService: DeviceDetectorService) {}

  ngOnInit() {
    this.errorForm = new FormGroup({
      priority: new FormControl(null, [Validators.required]),
      screenshot: new FormControl(null, []),
      comment: new FormControl(null, [Validators.required]),
    });
  }

  openErrorMenu() {
    this.errorForm.get('priority').setValue(null);
    this.errorForm.get('screenshot').setValue(null);
    this.errorForm.get('comment').setValue(null);
    let newimg = document.createElement("img");
    newimg.setAttribute("width", "297px");
    html2canvas(document.querySelector("html")).then(canvas => {      
      document.querySelector("#screenshot-block").appendChild(newimg);
      canvas.toBlob((blob) => {
        newimg.setAttribute("src", URL.createObjectURL(blob));
        this.errorForm.get('screenshot').setValue(blob);
      }, 'image/png');
    });
    this.isErrorMenuOpen = true;
  }

  closeErrorMenu() {
    this.isErrorMenuOpen = false;
    this.showSuccess = false;
    this.closeMenu.emit("close");
  }

  selectPriority(val) {
    this.errorForm.get('priority').setValue(val);
  }

  delScreenshot() {
    let newimg = document.querySelector("#screenshot-block img");
    this.errorForm.get('screenshot').setValue(null);
    newimg.setAttribute("src", "");
  }

  onSubmit() {
    if(this.errorForm.valid) {
      this.submitting = true;
      let img = this.errorForm.get('screenshot').value;
      let value = {priority: this.errorForm.get('priority').value,comment: this.errorForm.get('comment').value, noImg: img==null};
      console.log(value);
      this.loggerService.bugReportSave(value, img).subscribe(data => {
        this.submitting = false;
        this.closeErrorMenuTimer();
        console.log(data);
      }, error => {
        this.submitting = false;
        console.log(error);
      });
    }
  }

  closeErrorMenuTimer() {
    this.showSuccess = true;
    setTimeout(() => {
      this.closeErrorMenu();
    }, 5000);
  }
}
