import { Injectable, EventEmitter } from '@angular/core';
import { Subscription, interval } from 'rxjs';

import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { PlayerService } from './player.service';
import { VideoService } from './video.service';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class TimerService {

  update$ = new EventEmitter();
  private _clockSubscription: Subscription;
  expiredDialogRef;
  dialogRef;
  min: number = 1;
  sec: number = 0;
  videoButtonClicked: boolean;
  isTimerActive = false;


  constructor(private router: Router, private http : HttpClient, private playerService :PlayerService, private videoService: VideoService,
    private userService: UserService) {
  }

  startLogoutTimer() {
    localStorage.setItem('logoutExpiredDate', JSON.stringify(this.getLogoutExpiredDate()));
    this._clockSubscription && this._clockSubscription.unsubscribe();
    this._clockSubscription = interval(1000).subscribe(val => this.setRemainingTime());
  }

  scheduleTimerStop(userId, seconds) {
    return this.http.post<any>("/api/scheduleTimerStop/"+userId+"/"+seconds,null);
  }

  setRemainingTime() {
   console.log(this.min+" "+this.sec);
    if (this.min>=0 && this.sec>=0 && localStorage.getItem('logoutExpiredDate')) {
      let client = JSON.parse(localStorage.getItem('client'));
      let expiredDate = JSON.parse(localStorage.getItem('logoutExpiredDate'));
      let _diff = Date.parse(expiredDate) - Date.parse(new Date().toString());
      this.min = this.getMinutes(_diff);
      this.sec = this.getSeconds(_diff);
      if(this.min<=0 && this.sec<=0) {
        this.userService.setUnregisteredUserNonActive(client.id).subscribe(() => {
        });
        this._clockSubscription && this._clockSubscription.unsubscribe();
        this.hide();
        localStorage.removeItem('secret_code');
        this.videoService.unPublish = false;
        this.router.navigate(['/user/join/'+client.therapist.joinLink]);
      }
    }
  }

  hide() {
    this.playerService.getParticipants().subscribe(data => {
      console.log(data);
      data.forEach(player => {
        player.showText = false;
      });
      this.saveAll(data);
    }, error => {
      console.log(error);
    });
  }

  saveAll(players) {
    this.playerService.saveAll(players, false).subscribe(data => {
      console.log(data);
    }, error => {
      console.log(error);
    });
  }

  getLogoutExpiredDate() {
    this.min = 10;
    this.sec = 0;
    let date = new Date();
    date.setMinutes(date.getMinutes() + 1);
    return date;
  }

  clearLogoutExpiredDate() {
    this._clockSubscription && this._clockSubscription.unsubscribe();
    localStorage.removeItem('logoutExpiredDate');
  }

  getMinutes(t) {
      return Math.floor( (t / 1000 / 60) % 60 );
  }

  getSeconds(t) {
      return Math.floor( (t / 1000) % 60 );
  }

  getUserTimerData(userId) {
    return this.http.get<any>("/api/timer/"+userId);
  }

  startTimer(userId) {
    return this.http.post<any>("/api/startTimer/"+userId,null);
  }

  stopTimer(userId) {
    return this.http.post<any>("/api/stopTimer/"+userId,null);
  }
}
