<h1 mat-dialog-title>{{'EVENT.FINALIZE_EVENT' | translate}}</h1>

<div class="content-container" mat-dialog-content>


  <img class="picture" [src]="sbEvent.thumbnail" height="200" *ngIf="sbEvent.thumbnail">

  <br>
  <p>{{'EVENT.EVENT_NAME' | translate}}: {{ sbEvent.eventName }}</p>

  <p *ngIf="sbEvent.type == 'Group Event'">{{'EVENT.MAX_PARTICIPANTS' | translate}}: {{ sbEvent.numberOfParticipants }}</p>

  <br>

  <p *ngIf="sbEvent.eventLocationRadio == 'Offline Event'">{{'EVENT.LOCATION' | translate}}: {{ 'EVENT.'+sbEvent.eventLocationRadio | translate }} / {{ sbEvent.eventLocation }}</p>
  <p *ngIf="sbEvent.eventLocationRadio == 'Online Event'">{{'EVENT.LOCATION' | translate}}: {{ 'EVENT.'+sbEvent.eventLocationRadio | translate }}</p>

  <p>{{'EVENT.EVENT_DATE' | translate}}:

  </p>
  <ul>
    <li *ngFor="let detail of formatedDates">
      {{detail}}
    </li>
  </ul>

  <p>{{'EVENT.START_TIME' | translate}}: {{ (sbEvent.startTime.hour < 10 ? '0'+sbEvent.startTime.hour : sbEvent.startTime.hour) + ':' + (sbEvent.startTime.minute < 10 ? '0'+sbEvent.startTime.minute : sbEvent.startTime.minute) }};
    {{'EVENT.END_TIME' | translate}}: {{ (sbEvent.endTime.hour < 10 ? '0'+sbEvent.endTime.hour : sbEvent.endTime.hour) + ':' + (sbEvent.endTime.minute < 10 ? '0'+sbEvent.endTime.minute : sbEvent.endTime.minute) }}</p>

  <p>{{'EVENT.EVENT_PRICE' | translate}}: {{ sbEvent.price }} {{ sbEvent.currency }} </p>

  <p>{{'EVENT.EVENT_DETAILS' | translate}}: {{ sbEvent.eventDetails }} </p>

  <button (click)="save()" mat-raised-button color="basic" [disabled]="saving">{{'EVENT.FINALIZE_EVENT' | translate}}</button>
  <br>
  <p>{{'EVENT.CLICK_OUTSIDE' | translate}}</p>

</div>
