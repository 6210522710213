import { Injectable } from '@angular/core';
import { HttpRequest, HttpResponse, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';

@Injectable()
export class TherapistInterceptor implements HttpInterceptor {
    intercept(request: HttpRequest<any>, next: HttpHandler) {
        let therapist = JSON.parse(localStorage.getItem('therapist'));
        if (therapist) {
            request = request.clone({
                setHeaders: {
                    "X-THERAPIST-ID" : therapist.id + ''
                }
            });
        }

        return next.handle(request);
    }
}
