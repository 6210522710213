import { APP_INITIALIZER, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ErrorComponent } from '../views/error/error.component';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { VideoService } from './services/video.service';
import { LoggerService } from './services/logger.service';
import { SocketService } from './services/socket.service';
import { SocketSubscriptionService } from './services/socket-subscription.service';
import { EventService } from './services/event.service';
import { SessionService } from './services/session.service';
import { CronJobService } from './services/cronjob.service';
import { TemplateService } from './services/template.service';


@NgModule({
  declarations: [ErrorComponent],
  imports: [
    RouterModule,
    CommonModule,
    TranslateModule,
    PerfectScrollbarModule,
  ],
  exports: [
    PerfectScrollbarModule,
    RouterModule,
    ErrorComponent,
    TranslateModule,
    CommonModule
  ],
  providers: [
    VideoService,
    LoggerService,
    SocketSubscriptionService,
    SocketService,
    EventService,
    SessionService,
    CronJobService,
    TemplateService
  ]
})
export class SharedModule { }
