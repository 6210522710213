import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  isTrial = false;

  constructor(private http : HttpClient) {
  }

  getAllUnregisterByTherapistIdAndJoinedOnAfterDateAndActive(therapistId) {
    return this.http.post<any>("/api/getAllUnregisterByTherapistIdAndJoinedOnAfterDateAndActive/"+therapistId,null);
  }

  getAllUnregisterByTherapistId(therapistId) {
    return this.http.post<any>("/api/getAllUnregisterByTherapistId/"+therapistId,null);
  }

  updateUnregisteredUserSelected(user) {
    return this.http.post<any>("/api/updateUnregisteredUserSelected",user);
  }

  setUnregisteredUserActive(userId) {
    return this.http.post<any>("/api/setUnregisteredUserActive/"+userId,null);
  }

  setUnregisteredUserNonActive(userId) {
    return this.http.post<any>("/api/setUnregisteredUserNonActive/"+userId,null);
  }

  setUnregisteredUserRole(userId,participantId) {
    return this.http.post<any>("/api/setUnregisteredUserRole/"+userId+"/"+participantId,null);
  }

  setUnregisteredUserActiveRole(userId,participantId) {
    return this.http.post<any>("/api/setUnregisteredUserActiveRole/"+userId+"/"+participantId,null);
  }

  removeUnregisteredUserRole(userId,participantId) {
    return this.http.post<any>("/api/removeUnregisteredUserRole/"+userId+"/"+participantId,null);
  }

  getMyDetails() {
    let therapist = JSON.parse(localStorage.getItem('therapist'));
    return this.http.post<any>("/api/getUser/"+therapist.id,null);
  }

  uploadImage(file) {
    const formData: FormData = new FormData();
    formData.append('file', file);
    let therapist = JSON.parse(localStorage.getItem('therapist'));
    return this.http.post<any>("/api/image/profile/upload/"+therapist.id,formData);
  }

  getTherapistImage() {
    let therapist = JSON.parse(localStorage.getItem('therapist'));
    const requestOptions: Object = {
      /* other options here */
      responseType: 'blob'
    }
    return this.http.get<any>("/api/image/"+therapist.id, requestOptions);
  }

  saveProfile(data) {
    return this.http.post<any>("/api/profile/update",data);
  }

  checkPassword(password) {
    let therapist = JSON.parse(localStorage.getItem('therapist'));
    return this.http.post<any>("/api/profile/check/password/"+password+"/"+therapist.id,null);
  }
}
