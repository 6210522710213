<h1 mat-dialog-title>{{'EVENT.PARTICIPANT_LIST' | translate}}</h1>

<div class="content-container" mat-dialog-content>

  <button mat-raised-button color="primary" [disabled]="(sbEvent.maximumParticipant-participants.length)==0" (click)="addParticipants()">{{'EVENT.ADD_PARTICIPANT' | translate}}</button>

  <br>

  <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef>  </th>
      <td mat-cell *matCellDef="let element">
        <button [ngStyle]="element.doubleApproval && {'color':'red'}"  matSuffix mat-icon-button aria-label="Clear" (click)="deleteFirst(element)">
          <mat-icon>delete</mat-icon>
        </button>
        <button matSuffix mat-icon-button aria-label="Clear" *ngIf="element.doubleApproval" (click)="cancelDelete(element)">
          <mat-icon>cancel</mat-icon>
        </button>
      </td>
    </ng-container>

    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef> {{'EVENT.NAME' | translate}} </th>
      <td mat-cell *matCellDef="let element"> {{element?.firstName}} {{element?.lastName}}</td>
    </ng-container>

    <ng-container matColumnDef="email">
      <th mat-header-cell *matHeaderCellDef> {{'EVENT.EMAIL_ADDRESS' | translate}} </th>
      <td mat-cell *matCellDef="let element"> {{element?.email}} </td>
    </ng-container>

    <ng-container matColumnDef="phone">
      <th mat-header-cell *matHeaderCellDef> {{'EVENT.PHONE_NUMBER' | translate}} </th>
      <td mat-cell *matCellDef="let element">{{element?.phoneNumber}}</td>
    </ng-container>

    <ng-container matColumnDef="registrationDate">
      <th mat-header-cell *matHeaderCellDef> {{'EVENT.REGISTRATION_DATE_TIME' | translate}} </th>
      <td mat-cell *matCellDef="let element"> {{element?.registrationDate | date:'MM/dd/yyyy h:mm a'}} </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

    <!-- Row shown when there is no matching data. -->
    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell" colspan="4">{{'EVENT.NO_PARTICIPANTS' | translate}}"</td>
    </tr>
  </table>
  <br>
  <p>{{'EVENT.CLICK_OUTSIDE' | translate}}</p>
</div>
