import { Component, OnInit, OnDestroy, HostListener, TemplateRef, AfterViewInit, ViewChild, Output, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { SidebarService, ISidebar } from '../sidebar/sidebar.service';
import { Router } from '@angular/router';
import { LangService, Language } from 'src/app/shared/lang.service';
import { AuthService } from 'src/app/shared/auth.service';
import { environment } from 'src/environments/environment';
import { TimerService } from 'src/app/shared/services/timer.service';
import { StompService } from '@stomp/ng2-stompjs';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { PlayerService } from 'src/app/shared/services/player.service';
import { LoggerService } from 'src/app/shared/services/logger.service';
import { BugReportingComponent } from '../bug-reporting/bug-reporting.component';
import { UserService } from 'src/app/shared/services/user.service';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import { EmailSendingService } from 'src/app/shared/services/email-sending.service';
import { DialogContentFourHours, DialogContentTimeExpired } from 'src/app/components/dialog-content/dialog-content.component';
import { AccountInfoService } from 'src/app/components/account-info/account-info.service';
import { SocketService } from 'src/app/shared/services/socket.service';
import { SocketSubscriptionService } from 'src/app/shared/services/socket-subscription.service';
import { TemplateService } from 'src/app/shared/services/template.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-topnav',
  templateUrl: './topnav.component.html'
})
export class TopnavComponent implements OnInit, OnDestroy, AfterViewInit {
  sidebar: ISidebar;
  subscription: Subscription;
  languages: Language[];
  currentLanguage: string;
  isSingleLang;
  isFullScreen = false;
  isDarkModeActive = false;
  searchKey = '';
  timerInterval;
  timerSubscription: Subscription;
  timerSeconds;
  timerMinutes;
  timerHour = '00';
  timerMin = '00';
  fromClose = false;
  smileHover = false;
  smileDisabled = false;
  hasTestPeriodEnded = false;
  dialogConfig = new MatDialogConfig();
  trailTimeSec = 18000;
  //trailTimeSec = 300;
  expiringTrailTimeSec = 14400;
  //expiringTrailTimeSec = 240;
  isTimerActive = false;
  noOfTrees = 0;
  treeHover = false;
  lastTreeHour = 0;
  treeshowEvery = 3600*3;
  //treeshowEvery = 60;
  fromClient = false;
  treeTimerSubscription;
  dataImage: any;

  @ViewChild(BugReportingComponent) bugReportingComponent:BugReportingComponent;

  constructor(private sidebarService: SidebarService, private authService: AuthService, private router: Router, private langService: LangService, private timerService: TimerService, private _stompService: StompService,private modalService: BsModalService
    , private playerService :PlayerService, private loggerService: LoggerService,  private userService: UserService,
    private mailSendingService: EmailSendingService, private dialog: MatDialog, private accountInfoService: AccountInfoService,
    private socketService: SocketService, private socketSubscriptionService: SocketSubscriptionService, public templateService: TemplateService,
    private sanitizer: DomSanitizer) {
    this.socketService.init();
    this.languages = this.langService.supportedLanguages;
    this.currentLanguage = this.langService.languageShorthand;
    this.isSingleLang = this.langService.isSingleLang;
    this.isDarkModeActive = this.getColor().indexOf('dark') > -1 ? true : false;
    //let previousValue = localStorage.getItem("canvasResize");
    //this.isDarkModeActive = previousValue && previousValue=="true";
    this.templateService.setTemplate();
    this.getTherapistImage();
  }

  modalRef: BsModalRef;
  config = {       backdrop: true,       ignoreBackdropClick: true     };
  @ViewChild('template') public template: TemplateRef<any>;
  openModal() {
    console.log("opening modal if falsy : " + this.modalRef);
    if(!this.modalRef) {
      this.modalRef = this.modalService.show(this.template, this.config);
    }
  }

  modalTimer = 0;
  modalTimerMins = '25';
  modalTimerSeconds = '00';
  warningtimerInterval = null;
  warningModalIsShowing = false;
  @ViewChild('timeoutWarning') public timeoutWarning: TemplateRef<any>;
  openTimeoutWarningModal() {
    if(!this.modalRef) {
      this.modalRef = this.modalService.show(this.timeoutWarning, this.config);
      this.modalTimer = 1500;
      this.warningModalIsShowing = true;
      this.warningtimerInterval = setInterval(() => {
        this.modalTimer--;
        if(this.modalTimer == 0) {
          this.showSessionTimedOutModal();
        } else {
          let tempTimerMin = Math.floor(this.modalTimer / 60);
          let tempTimerSec = (this.modalTimer - (tempTimerMin * 60));
          this.modalTimerMins = tempTimerMin.toString().padStart(2,'0');
          this.modalTimerSeconds = tempTimerSec.toString().padStart(2,'0');
        }
      }, 1000);
    }
  }

  @ViewChild('timedOut') public timedOut: TemplateRef<any>;
  showSessionTimedOutModal() {
    this.modalRef.hide();
    this.modalRef = null;
    clearInterval(this.warningtimerInterval);
    this.modalRef = this.modalService.show(this.timedOut, this.config);
  }

  closeTimeoutWarningModal() {
    this.warningModalIsShowing = false;
    this.modalRef.hide();
    this.modalRef = null;
    clearInterval(this.warningtimerInterval);
    this.initTimerWithModal(false, false);
  }

  closeModal() {
    this.modalRef.hide();
    this.modalRef = null;
    this.initTimerWithModal(false, false);
  }

  @HostListener('window:focus', ['$event'])
  onFocus(event: any): void {
    console.log("checking timer through tab switch");
    if(this.isTherapistUser()) {
      this.initTimerWithModal(false, false);
      this.timerService.update$.emit(true);
    }
  }


  @HostListener('window:blur', ['$event'])
  onBlur(event: any) {
    console.log("tab blur");
    let user = JSON.parse(localStorage.getItem('user'));
    if(user.authorities[0] == "ROLE_THERAPIST" && !this.fromClose && !this.warningModalIsShowing) {
      console.log("scheduling timer stop for 30 mins");
      this.timerService.scheduleTimerStop(user.id, 1800).subscribe(data => {
      });
    }

  }


  @HostListener('window:beforeunload', ['$event'])
  beforeUnloadHander(event) {
    console.log("tab close");
    this.fromClose = true;
    /* if(this.isTherapistUser()) {
      console.log("scheduling timer stop for 10 mins");
      let user = JSON.parse(localStorage.getItem('user'));
      this.timerService.scheduleTimerStop(user.id, 600).subscribe(data => {
      });
    } else {
      let client = JSON.parse(localStorage.getItem('client'));
      this.userService.setUnregisteredUserNonActive(client.id).subscribe(() => {
      });
    } */
  }

initTimer() {
  this.initTimerWithModal(false, false);
}

warningPopupSubscription : Subscription;
initTimerWithModal(withModal, redirect) {
  if(this.warningModalIsShowing) {
    return;
  }
  let user = JSON.parse(localStorage.getItem('user'));
  if(user.authorities[0] == "ROLE_THERAPIST") {
    let userId = user.id;
    console.log("initTimer");
    this.socketSubscriptionService.getTimerUpdate().subscribe(data => {
      this.timerUpdate(data);
    });
    this.timerService.getUserTimerData(userId).subscribe(timerData => {
        console.log(timerData);
        this.timerSeconds = timerData.counterState;
        this.updateTimerView(redirect);
        this.isTimerActive = true;
        this.noOfTrees = parseInt((timerData.counterState / this.treeshowEvery)+"");
        if(timerData.counting && (!this.userService.isTrial || this.timerSeconds <= this.trailTimeSec)) {
          console.log("starting timer from DB data");
          this.startTimer();
          if(withModal && !this.timerService.videoButtonClicked) {
            this.openModal();
          }
          if(this.timerService.videoButtonClicked) {
            this.timerService.videoButtonClicked = false;
          }
        }
        else {
          console.log("stopping timer from DB data");
          this.isTimerActive = false;
          this.stopTimer();
        }
    });

    if(this.warningPopupSubscription) {
      this.warningPopupSubscription.unsubscribe();
    }
    this.socketSubscriptionService.getDialog().subscribe(data => {
      if(data == "timeoutwarning"){
        console.log("got message to open dialog");
        this.openTimeoutWarningModal();
      }
    });
  } else {
    let therapist = JSON.parse(localStorage.getItem('therapist'));
    this.timerService.getUserTimerData(therapist.id).subscribe(timerData => {
      this.noOfTrees = parseInt((timerData.counterState / this.treeshowEvery)+"");
      if(timerData.counting) {
        this.isTimerActive = true;
        this.showTreeHover();
      }
      else {
        this.isTimerActive = false;
      }
    });
    this.socketSubscriptionService.getTimerUpdate().subscribe(data => {
      this.clientTimerUpdate(data);
    });
  }
}

timerUpdate = (data) => {
  console.log("message arrived on timer update");
  console.log(data);
  console.log(this.timerSeconds);
  if(data == "true" && (!this.userService.isTrial || this.timerSeconds <= this.trailTimeSec)) {
    this.startTimer();
    this.isTimerActive = true;
  } else {
    this.stopTimer();
    this.isTimerActive = false;
  }
};

clientTimerUpdate = (data) => {
  if(data == "true") {
    this.isTimerActive = true;
  } else {
    this.isTimerActive = false;
  }
};

stopTimer() {
  console.log("timer stopped");
  clearInterval(this.timerInterval);
  this.timerInterval = null;
}

startTimer() {
  console.log("timer counting");
  console.log(this.timerInterval);
  if(!this.timerInterval) {
    this.timerInterval = setInterval(() => {
      this.timerSeconds++;
      this.updateTimerView(false);
    },1000);
  }
}

updateTimerView(redirect) {
  //console.log(this.timerSeconds);
  //console.log(this.trailTimeSec);
  if (this.timerSeconds >= this.trailTimeSec) {
      //console.log("testttttttt");
      //console.log(this.userService.isTrial);
      if(this.userService.isTrial) {
        if(redirect) {
          document.getElementById("play-area").style.pointerEvents="none";
          document.getElementById("play-area").style.cursor="default";
          this.router.navigate(['/app/welcome']);
          return;
        }
        console.log(this.router.url);
        if(this.router.url=="/app/play-area") {
          this.dialogConfig.disableClose = true;
          this.dialog.closeAll();
          this.dialog.open(DialogContentTimeExpired, this.dialogConfig);
          this.hasTestPeriodEnded = true;
          this.isTimerActive = false;
          setTimeout(() => {
            if(this.treeTimerSubscription) {
              this.treeTimerSubscription.unsubscribe();
            }
            this.stopTimer();
            let user = JSON.parse(localStorage.getItem('user'));
            if(user) {
              if(user.authorities[0] == "ROLE_THERAPIST") {
                this.timerService.stopTimer(user.id).subscribe(data => {
                  console.log("timer stopped on destroy");
                });
              }
            }
          }, 200);
        }
      } 
        this.timerMinutes = Math.floor(this.timerSeconds / 60);
        this.timerHour = Math.floor(this.timerMinutes / 60).toString();
        this.timerMin = (this.timerMinutes % 60).toString();
        this.timerHour = this.timerHour.padStart(2, '0');
        this.timerMin = this.timerMin.padStart(2, '0');
        this.noOfTrees = parseInt((this.timerSeconds / this.treeshowEvery)+"");
        //let hour = Math.floor(this.timerMinutes / 60);
        /* if(hour==this.lastTreeHour+1) {
          this.treeHover = true;
          this.lastTreeHour = hour;
        } */
        let min = Math.floor(this.timerSeconds / this.treeshowEvery);
        if(min>1 && this.lastTreeHour==0) {
          this.lastTreeHour = min;
        }
        if(min==this.lastTreeHour+1) {
          this.showTreeHover();
          this.lastTreeHour = min;
        }
      
  } else if (this.timerSeconds == this.expiringTrailTimeSec) {
      if(this.userService.isTrial) {
        this.mailSendingService.send4HoursWarningEmail(JSON.parse(localStorage.getItem('user'))).subscribe(() => {
          console.log('mail sent');
        });
        this.dialogConfig.disableClose = false;
        this.dialog.open(DialogContentFourHours, this.dialogConfig);
      }
  } else {
    this.timerMinutes = Math.floor(this.timerSeconds / 60);
    this.timerHour = Math.floor(this.timerMinutes / 60).toString();
    this.timerMin = (this.timerMinutes % 60).toString();
    this.timerHour = this.timerHour.padStart(2, '0');
    this.timerMin = this.timerMin.padStart(2, '0');
    this.noOfTrees = parseInt((this.timerSeconds / this.treeshowEvery)+"");
    //let hour = Math.floor(this.timerMinutes / 60);
    /* if(hour==this.lastTreeHour+1) {
      this.treeHover = true;
      this.lastTreeHour = hour;
    } */
    let min = Math.floor(this.timerSeconds / this.treeshowEvery);
    if(min>1 && this.lastTreeHour==0) {
      this.lastTreeHour = min;
    }
    if(min==this.lastTreeHour+1) {
      this.showTreeHover();
      this.lastTreeHour = min;
    }
  }
}

  onDarkModeChange(event) {
    let color = this.getColor();
    if (color.indexOf('dark') > -1) {
      color = color.replace('dark', 'light');
    } else if (color.indexOf('light') > -1) {
      color = color.replace('light', 'dark');
    }
    localStorage.setItem(environment.themeColorStorageKey, color);
    setTimeout(() => {
      window.location.reload();
    }, 200);
  }

  get displayName() {
    let user = JSON.parse(localStorage.getItem('user'));
    if(user) {
      if(user.firstName && user.lastName) {
        return user.firstName + " " + user.lastName;
      }
    }
    else return "";
  }

  getColor() {
    return localStorage.getItem(environment.themeColorStorageKey)
      ? localStorage.getItem(environment.themeColorStorageKey)
      : environment.defaultColor;
  }

  fullScreenClick() {
    this.isFullScreen = !this.isFullScreen;
    if (this.isFullScreen) {
      document.documentElement.requestFullscreen();
    } else {
      document.exitFullscreen();
    }
  }

  onLanguageChange(lang) {
    this.langService.language = lang.code;
    this.currentLanguage = this.langService.languageShorthand;
  }

  ngOnInit() {
    this.playerService.getSvgFigures();
    this.subscription = this.sidebarService.getSidebar().subscribe(
      res => {
        this.sidebar = res;
      },
      err => {
        console.error(`An error occurred: ${err.message}`);
      }
    );
    let user = JSON.parse(localStorage.getItem('user'));
    this.initTimerWithModal(false, true);
    if(!this.isTherapistUser()) {
      console.log(this.isPageRefresh());
      if(this.isPageRefresh()) {
        let client = JSON.parse(localStorage.getItem('client'));
        this.userService.setUnregisteredUserActive(client.id).subscribe(() => {
        });
      } else {
        sessionStorage.setItem("page_refresh", "true");
      }
      this.fromClient = true;
      this.treeTimerSubscription = setInterval(() => {
        //console.log("client...");
        let therapist = JSON.parse(localStorage.getItem('therapist'));
        this.timerService.getUserTimerData(therapist.id).subscribe(timerData => {
          this.noOfTrees = parseInt((timerData.counterState / this.treeshowEvery)+"");
          if(timerData.counting) {
            this.isTimerActive = true;
            let min = Math.floor(timerData.counterState / this.treeshowEvery);
            if(min>1 && this.lastTreeHour==0) {
              this.lastTreeHour = min;
            }
            if(min==this.lastTreeHour+1) {
              this.showTreeHover();
              this.lastTreeHour = min;
            }
          }
          else {
            this.isTimerActive = false;
          }
        });
      }, 3000);
    } else {
      document.addEventListener('visibilitychange', () => {
        if (this.fromClose) { 
          if(this.isTherapistUser()) {
            console.log("scheduling timer stop for 10 mins");
            let user = JSON.parse(localStorage.getItem('user'));
            this.timerService.scheduleTimerStop(user.id, 600).subscribe(data => {
            });
          } else {
            let client = JSON.parse(localStorage.getItem('client'));
            this.userService.setUnregisteredUserNonActive(client.id).subscribe(() => {
            });
          }
        } else if (document.visibilityState != 'hidden') {
        }
      });
      this.accountInfoService.getCurrentUserData().subscribe((data) => {
        console.log(data);
        if(data && data.type=="TRIAL") {
          this.userService.isTrial = true;
          this.initTimerWithModal(false, true);
        } else {
          this.userService.isTrial = false;
        }
      }, error => {
      });
    }
  }

  ngAfterViewInit() {
  }

  ngOnDestroy(): void {
    if(this.subscription) {
      this.subscription.unsubscribe();
   }
   if(this.timerSubscription) {
    this.timerSubscription.unsubscribe();
   }
   if(this.warningPopupSubscription) {
     this.warningPopupSubscription.unsubscribe();
   }
    let user = JSON.parse(localStorage.getItem('user'));
    if(user) {
      if(user.authorities[0] == "ROLE_THERAPIST") {
        this.timerService.stopTimer(user.id).subscribe(data => {
          console.log("timer stopped on destroy");
        });
      }
    }
  }

  menuButtonClick = (e: { stopPropagation: () => void; }, menuClickCount: number, containerClassnames: string) => {
    if (e) { e.stopPropagation(); }

    setTimeout(() => {
      const event = document.createEvent('HTMLEvents');
      event.initEvent('resize', false, false);
      window.dispatchEvent(event);
    }, 350);

    this.sidebarService.setContainerClassnames(
      ++menuClickCount,
      containerClassnames,
      this.sidebar.selectedMenuHasSubItems
    );
  }

  mobileMenuButtonClick = (event: { stopPropagation: () => void; }, containerClassnames: string) => {
    if (event) { event.stopPropagation(); }
    this.sidebarService.clickOnMobileMenu(containerClassnames);
  }

  onSignOut() {
    let user = JSON.parse(localStorage.getItem('user'));
    let client = JSON.parse(localStorage.getItem('client'));
    this.hide();
    this.loggerService.destroy();
    this.loggerService.log("signOut...");
    this.loggerService.saveLogToServer();
    let isTherapistUser = this.isTherapistUser();
    if(!isTherapistUser) {
      this.userService.setUnregisteredUserNonActive(client.id).subscribe(() => {
      });
    }
    this.authService.signOut(user.id).subscribe(() => {
      if(isTherapistUser) {
        this.router.navigate(['/']).then(() => {
          window.location.reload();
        });
      } else {
        this.router.navigate(['/user/join/'+client.therapist.joinLink]).then(() => {
          window.location.reload();
        });
      }
    });
  }

  hide() {
    this.playerService.getParticipants().subscribe(data => {
      console.log(data);
      data.forEach(player => {
        player.showText = false;
      });
      this.saveAll(data);
    }, error => {
      console.log(error);
    });
  }

  saveAll(players) {
    this.playerService.saveAll(players, false).subscribe(data => {
      console.log(data);
    }, error => {
      console.log(error);
    });
  }

  searchKeyUp(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      this.search();
    } else if (event.key === 'Escape') {
      const input = document.querySelector('.mobile-view');
      if (input && input.classList) { input.classList.remove('mobile-view'); }
      this.searchKey = '';
    }
  }

  searchAreaClick(event) {
    event.stopPropagation();
  }
  searchClick(event) {
    if (window.innerWidth < environment.menuHiddenBreakpoint) {
      let elem = event.target;
      if (!event.target.classList.contains('search')) {
        if (event.target.parentElement.classList.contains('search')) {
          elem = event.target.parentElement;
        } else if (
          event.target.parentElement.parentElement.classList.contains('search')
        ) {
          elem = event.target.parentElement.parentElement;
        }
      }

      if (elem.classList.contains('mobile-view')) {
        this.search();
        elem.classList.remove('mobile-view');
      } else {
        elem.classList.add('mobile-view');
      }
    } else {
      this.search();
    }
    event.stopPropagation();
  }

  search() {
    if (this.searchKey && this.searchKey.length > 1) {
      this.router.navigate(['/app/pages/miscellaneous/search'], { queryParams: { key: this.searchKey.toLowerCase().trim() } });
      this.searchKey = '';
    }
  }

  @HostListener('document:click', ['$event'])
  handleDocumentClick(event) {
    const input = document.querySelector('.mobile-view');
    if (input && input.classList) { input.classList.remove('mobile-view'); }
    this.searchKey = '';
  }

  clickToOpenErrorMenu() {
    document.getElementById("hov_tooltip").style.display = "none";
    let cssList = document.getElementById("app-container").classList;
    for(let i=0;i<cssList.length;i++) {
      if(cssList[i]=="main-hidden") {
        document.getElementById("side-menu").style.display = "none";
        console.log(cssList[i]);
      }
    }
    this.smileDisabled = true;
    this.bugReportingComponent.openErrorMenu();
    setTimeout(() => {
      document.getElementById("side-menu").style.display = "";
      document.getElementById("hov_tooltip").style.display = "";
    }, 1000);
  }

  hideErrorMenu(event) {
    this.smileDisabled = false;
  }

  isTherapistUser() {
    let user = JSON.parse(localStorage.getItem('user'));
    if(user && user.roles && user.roles[0] == 'ROLE_THERAPIST') {
      return true;
    }
    return false;
  }

  isPageRefresh() {
    let page = sessionStorage.getItem("page_refresh");
    if(page) {
      return true;
    }
    return false;
  }

  showTreeHover() {
    this.treeHover = true;
    setTimeout(() => {
      this.treeHover = false;
    }, 5000);
  }

  closeTreeHover() {
    this.treeHover = false;
  }

  getTherapistImage() {
    this.userService.getTherapistImage().subscribe(data => {
      var reader = new FileReader();
        reader.readAsDataURL(data);
        reader.onload = (event) => {
          this.dataImage = this.sanitizer.bypassSecurityTrustUrl(event.target.result as string);
        }
    });
  }
}
