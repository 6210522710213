import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SessionService {

  public refreshEventList = new Subject<any>();

  constructor(private http : HttpClient) {
  }

  getAllTherapist() {
    return this.http.post<any>("/api/getAllTherapist", null);
  }

  getSessions(pageData, data) {
    return this.http.post<any>("/api/session/findAll"+"?page="+pageData.page+"&size="+pageData.size, data);
  }

  getSessionsDetails(pageData, data) {
    return this.http.post<any>("/api/session/findAll/details"+"?page="+pageData.page+"&size="+pageData.size, data);
  }

  sendEmail(data) {
    return this.http.post<any>("/api/session/sendEmail", data);
  }
}
