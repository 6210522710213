import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, ActivatedRoute } from '@angular/router';
import { StompService } from '@stomp/ng2-stompjs';
import { AuthService } from 'src/app/shared/auth.service';
import { SocketService } from 'src/app/shared/services/socket.service';

@Injectable()
export class AuthGuard implements CanActivate {

    constructor(private router: Router, private activatedRoute: ActivatedRoute, private socketService: SocketService, private authService: AuthService) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        console.log(localStorage.getItem('therapist'));
        console.log(state.url);
        if (localStorage.getItem('therapist') && state.url=="/") {
            let currentUser = JSON.parse(localStorage.getItem('user'));
            if(currentUser && currentUser.authorities[0] == "ROLE_CLIENT") {
                localStorage.removeItem('user');
            }
            currentUser = JSON.parse(localStorage.getItem('user'));
            if (currentUser) {
                let groups = route.data.groups;
                let no_groups = route.data.no_groups;
                let isCorrectUser = false;
                if (groups) {
                    groups.forEach(group => {
                        currentUser.authorities.forEach(loggin_group => {
                            if (loggin_group == group) {
                                isCorrectUser = true;
                            }
                        });
                    });
                } else {
                    isCorrectUser = true;
                }
                if (no_groups) {
                    no_groups.forEach(nogroup => {
                        currentUser.authorities.forEach(loggin_group => {
                            if (loggin_group == nogroup) {
                                isCorrectUser = false;
                            }
                        });
                    });
                }
                console.log(isCorrectUser);
                if (!isCorrectUser) {
                    this.router.navigate(['/user/login'], { queryParams: { returnUrl: state.url } });
                    return false;
                } else {
                    let therapist = JSON.parse(localStorage.getItem('therapist'));
                    this.authService.isTheripstLoggedIn(therapist.id).subscribe((value) => {
                        console.log(value);
                        if(!value) {
                            this.authService.signOut(therapist.id).subscribe(() => {
                                this.router.navigate(['/']);
                            });
                            return false;
                        }
                    });
                    this.socketService.publish("/app/login.addUser", JSON.stringify({userId: therapist.id, type: 'JOIN'}));
                    this.router.navigate(['/app/play-area']);
                    return false;
                }
            }
            return true;
        }
        if (localStorage.getItem('user')) {
            let currentUser = JSON.parse(localStorage.getItem('user'));
            console.log(currentUser);
            if (currentUser) {
                let groups = route.data.groups;
                let no_groups = route.data.no_groups;
                let isCorrectUser = false;
                if (groups) {
                    groups.forEach(group => {
                        currentUser.authorities.forEach(loggin_group => {
                            if (loggin_group == group) {
                                isCorrectUser = true;
                            }
                        });
                    });
                } else {
                    isCorrectUser = true;
                }
                if (no_groups) {
                    no_groups.forEach(nogroup => {
                        currentUser.authorities.forEach(loggin_group => {
                            if (loggin_group == nogroup) {
                                isCorrectUser = false;
                            }
                        });
                    });
                }
                console.log(isCorrectUser);
                if (!isCorrectUser) {
                    if(route.routeConfig.path=='play-area' && currentUser.authorities[0] == "ROLE_CLIENT") {
                        this.router.navigate(['/app/play-area-view']);
                    } else {
                        this.router.navigate(['/auth/unauthorized']);
                    }
                    return false;
                }
            }
            return true;
        }

        // not logged in so redirect to login page with the return url
        this.router.navigate(['/user/login'], { queryParams: { returnUrl: state.url } });
        return false;
    }
}