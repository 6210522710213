import { Injectable } from '@angular/core';
import { IFeedback } from 'src/app/components/dialog-content/dialog-content.component';
import { HttpClient } from '@angular/common/http';
import { User } from 'src/app/data/models/user.model';

@Injectable({
  providedIn: 'root'
})
export class EmailSendingService {

  constructor(private http: HttpClient) { }

  sendFeedbackEmail(feedback: IFeedback) {
    console.log('sending');
    return this.http.post('/api/sendFeedbackEmail/', feedback);
  }

  send4HoursWarningEmail(user: User) {
    return this.http.post('api/sendFourHourWarningMail', user);
  }
}
