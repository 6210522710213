import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class TemplateService {

  public templateColors = [];
  public templateBgImg = null;

  constructor(private http : HttpClient, private sanitizer: DomSanitizer) {
  }

  getAllTemplate() {
    let therapist = JSON.parse(localStorage.getItem('therapist'));
    return this.http.get<any>('/api/template/all/'+(therapist?therapist.id:0));
  }

  save(data) {
    return this.http.post<any>("/api/template/save", data);
  }

  uploadImage(file) {
    const formData: FormData = new FormData();
    formData.append('file', file);
    let therapist = JSON.parse(localStorage.getItem('therapist'));
    return this.http.post<any>("/api/template/image/upload/"+therapist.id,formData);
  }

  setTemplate() {
    this.getAllTemplate().subscribe(data => {
      if(data) {
        data.forEach(element => {
          this.templateColors[element.type] = element;
          if(element.type=="BACKGROUND_COLOR") {
            document.querySelector("html").style.background = this.templateColors[element.type].bgColor;
            document.body.style.background = this.templateColors[element.type].bgColor;
            if(document.getElementById("app-container")) {
              document.getElementById("app-container").style.background = this.templateColors[element.type].bgColor;
            }
          }
        });
        this.getTemplateImage().subscribe(data => {
          console.log(data);
          if(data && data.size>0) {
            this.templateBgImg = this.sanitizer.bypassSecurityTrustStyle("url('"+window.URL.createObjectURL(data)+"')");
            console.log(this.templateBgImg);
          }
        });
      }
    });
  }

  getTemplateImage() {
    let therapist = JSON.parse(localStorage.getItem('therapist'));
    const requestOptions: Object = {
      /* other options here */
      responseType: 'blob'
    }
    return this.http.get<any>("/api/template/image/"+therapist.id, requestOptions);
  }
}