<div *ngIf="isErrorMenuOpen && !showSuccess" class="dropdown-menu dropdown-menu-right mt-3" role="menu" aria-labelledby="button-basic" id="iconMenuDropdown" [ngStyle]="{'height': errorForm.get('screenshot')?.value==null ? '320px' : '575px','margin-right': deviceService.isMobile() ? '-86px' : '-192px', 'max-height': deviceService.isMobile() ? '80vh' : '', 'overflow-y': deviceService.isMobile() ? 'scroll' : ''}" style="width: 350px;display: initial;">
  <form [formGroup]="errorForm" #form="ngForm" (ngSubmit)="onSubmit()" novalidate>
    <div class="form-group">
      <label>{{ 'ERROR_FORM.PRIORITY' | translate }}</label>
      <a (click)="closeErrorMenu()" style="float: right;color: red;cursor: pointer;"><i class="fa fa-window-close" aria-hidden="true" style="font-size: 1.4rem;"></i></a><br>
      <button (click)="selectPriority('4')" type="button" class="btn btn-primary btn-xs mt-3" [ngStyle]="{'border': errorForm.get('priority')?.value=='4' ? '3px solid #000' : '#2F7626'}" style="margin-right: 10px;background-color: #2F7626;font-weight: bold;">{{ 'ERROR_FORM.LOW' | translate}}</button>
      <button (click)="selectPriority('3')" type="button" class="btn btn-primary btn-xs mt-3" [ngStyle]="{'border': errorForm.get('priority')?.value=='3' ? '3px solid #000' : '#F6C246'}" style="margin-right: 10px;background-color: #F6C246;border-color: #F6C246;font-weight: bold;color: #000;">{{ 'ERROR_FORM.MEDIUM' | translate}}</button>
      <button (click)="selectPriority('2')" type="button" class="btn btn-primary btn-xs mt-3" [ngStyle]="{'border': errorForm.get('priority')?.value=='2' ? '3px solid #000' : '#D82A2E'}" style="margin-right: 10px;background-color: #D82A2E;border-color: #D82A2E;font-weight: bold;">{{ 'ERROR_FORM.HIGH' | translate}}</button>
      <button (click)="selectPriority('1')" type="button" class="btn btn-primary btn-xs mt-3" [ngStyle]="{'border': errorForm.get('priority')?.value=='1' ? '3px solid #000' : '#A0010C'}" style="background-color: #A0010C;border-color: #A0010C;font-weight: bold;">{{ 'ERROR_FORM.HIGHEST' | translate}}</button>
      <div *ngIf="errorForm.get('priority')?.errors?.required && form.submitted" class="invalid-tooltip">{{ 'ERROR_FORM.PRIORITY_REQ' | translate }}</div>
    </div>
    <div [ngStyle]="{'display': errorForm.get('screenshot')?.value==null ? 'none' : ''}" class="form-group">
      <div id="screenshot-block"></div><br>
      <a (click)="delScreenshot()" style="color: red;cursor: pointer;"><i class="fa fa-trash" aria-hidden="true" style="font-size: 1.4rem;"></i></a> {{ 'ERROR_FORM.DO_NOT_SEND_SCR' | translate}}
    </div>
    <div class="form-group">
      <textarea placeholder="{{ 'ERROR_FORM.COMMENT' | translate }}" style="resize: none;min-width: 298px;min-height: 117px;" formControlName="comment"></textarea>
      <div *ngIf="errorForm.get('comment')?.errors?.required && form.submitted" class="invalid-tooltip" ng>{{ 'ERROR_FORM.COMMENT_REQ' | translate }}</div>
    </div>
    <div class="form-group">
      <button type="submit" [disabled]="submitting" class="btn btn-primary btn-md mt-3" style="font-weight: bold;">
        {{ 'ERROR_FORM.SEND' | translate}}
      </button>
    </div>
  </form>
</div>
<div *ngIf="isErrorMenuOpen && showSuccess" class="dropdown-menu dropdown-menu-right mt-3" role="menu" aria-labelledby="button-basic" id="iconMenuDropdown" [ngStyle]="{'height': errorForm.get('screenshot')?.value==null ? '320px' : '575px'}" style="width: 350px;margin-right: -192px;display: initial;">
  <div style="text-align: center;margin-top: 20px;">
    <Span>{{ 'ERROR_FORM.SUCCESS_MSG_TOP' | translate}}</Span><br>
    <Span>{{ 'ERROR_FORM.SUCCESS_MSG_BTM' | translate}}</Span>
  </div>
</div>