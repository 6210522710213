import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { IAccountInfo } from './account-info.component';

@Injectable({
  providedIn: 'root'
})
export class AccountInfoService {

  constructor(private http: HttpClient) { }

  getCurrentUserData() {
    return this.http.get<IAccountInfo>('/api/getCurrentUserData/');
  }

  updateCurrentUserInfo(userData: IAccountInfo) {
    console.log('saving')
    return this.http.post('/api/updateAccountInfo', userData);
  }
}
