<div id="content-container" mat-dialog-content>
    <span id="logo-center" class="logo-single"></span>

    <p>{{'NOTIFY_DIALOG.FOUR_HR_DIALOG_TXT_1' | translate}} {{firstName}}</p>
    <p>{{'NOTIFY_DIALOG.FOUR_HR_DIALOG_TXT_2' | translate}}</p>
    <p>{{'NOTIFY_DIALOG.FOUR_HR_DIALOG_TXT_3' | translate}}</p>
    <p>{{'NOTIFY_DIALOG.FOUR_HR_DIALOG_TXT_4' | translate}}</p>
    <button [mat-dialog-close] routerLink="/app/subscription/professional-package" id="plans-button" class="btn btn-primary">{{'NOTIFY_DIALOG.FOUR_HR_DIALOG_BTN' | translate}}</button>

    <p>{{'NOTIFY_DIALOG.FOUR_HR_DIALOG_CLOSE' | translate}}</p>
</div>

