import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import { Room } from 'src/app/data/models/room.model';
import { UserService } from './user.service';
import { LoggerService } from './logger.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { StompService } from '@stomp/ng2-stompjs';
import { VideoService } from './video.service';
import { SocketSubscriptionService } from './socket-subscription.service';
declare var $: any;
declare var Janus: any;

@Injectable({
  providedIn: 'root'
})
export class SocketService {

  subscription : Subscription;
  usersUpdatedOn = "";
  participantsUpdatedOn = "";
  startUpdate = true;

  constructor(private loggerService: LoggerService, private _stompService: StompService, private socketSubscriptionService: SocketSubscriptionService,
    private videoService: VideoService) { }

  init() {
    this.log("init.....");
    let therapist = JSON.parse(localStorage.getItem('therapist'));
    if(therapist && !this.subscription) {
      this.subscription = this._stompService.subscribe('/sb/user/'+therapist.id).subscribe(this.on_next_refresh);
      let data = {userId: "", userType: "", usersUpdatedOn: this.usersUpdatedOn, participantsUpdatedOn: this.participantsUpdatedOn}
      if(this.videoService.isTherapistUser()) {
        this.subscription = this._stompService.subscribe('/sb/user/only/'+therapist.id).subscribe(this.on_next_refresh);
        data.userId = therapist.id;
        data.userType = "THERAPIST";
      } else {
        let user = JSON.parse(localStorage.getItem('user'));
        let userId = user.id;
        this.subscription = this._stompService.subscribe('/sb/user/only/'+userId).subscribe(this.on_next_refresh);
        data.userId = userId;
        data.userType = "CLIENT";
      }
      //let socketUpdateInterval = setInterval(() => {
        console.log(this.usersUpdatedOn);
        console.log(this.participantsUpdatedOn);
        if(this.startUpdate) {
          this.startUpdate = false;
          data.usersUpdatedOn = this.usersUpdatedOn;
          data.participantsUpdatedOn = this.participantsUpdatedOn;
          this._stompService.publish("/app/check.update", JSON.stringify(data));
         /*  setTimeout(() => {
            this.startUpdate = true;
          }, 2000); */
        }
      //},5000);
    }
  }

  publish(url, data) {
    this._stompService.publish(url, data);
  }

  public on_next_refresh = (message: any) => {
    console.log(message);
    this.startUpdate = false;
    let body = JSON.parse(message.body);
    let userOutDatedData = false;
    let playersOutDatedData = false;
    if(body) {
      this.log(body.eventType);
      console.log(body.usersUpdatedOn);
      console.log(body.participantsUpdatedOn);
      console.log(((+body.usersUpdatedOn)<(+this.usersUpdatedOn)));
      console.log(((+body.participantsUpdatedOn)<(+this.participantsUpdatedOn)));
      if((+body.usersUpdatedOn)<(+this.usersUpdatedOn)) {
        this.startUpdate = true;
        userOutDatedData = true;
      }
      if((+body.participantsUpdatedOn)<(+this.participantsUpdatedOn)) {
        this.startUpdate = true;
        playersOutDatedData = true;
      }
      this.usersUpdatedOn = body.usersUpdatedOn;
      this.participantsUpdatedOn = body.participantsUpdatedOn;
      if(body.contents) {
        this.log(body.contents.length);
      }
    }
    if(body.eventType=="UNREGISTER_USER") {
      console.log(body);
      if(userOutDatedData) {
        console.log("rrrtty===>")
        return;
      }
      this.socketSubscriptionService.unRegisterUsers.next(body.contents);
    } else if(body.eventType=="DISCONNECTED") {
      this.socketSubscriptionService.disconnected.next(body.contents);
    } else if(body.eventType=="RECONNECTED") {
      this.socketSubscriptionService.reconnected.next(body.contents);
    } else if(body.eventType=="START_VIDEO") {
      this.socketSubscriptionService.startvideo.next(body.contents);
    } else if(body.eventType=="REFRESH_ADMIN") {
      this.socketSubscriptionService.refreshadmin.next(body.contents);
    } else if(body.eventType=="PLAYERS_VALUE") {
      if(playersOutDatedData) {
        return;
      }
      this.socketSubscriptionService.refreshPlayersValue.next(body.contents ? body.contents : body.content);
    } else if(body.eventType=="LOGIN") {
      this.socketSubscriptionService.login.next(body.content);
    } else if(body.eventType=="LOGOUT") {
      this.socketSubscriptionService.logout.next(body.content);
    } else if(body.eventType=="SESSION_OUT") {
      this.socketSubscriptionService.sessionout.next(body.content);
    } else if(body.eventType=="TIMER_UPDATE") {
      this.socketSubscriptionService.timerUpdate.next(body.content);
    } else if(body.eventType=="DIALOG") {
      this.socketSubscriptionService.dialog.next(body.content);
    }
    setTimeout(() => {
      this.startUpdate = true;
    }, 1000);
  }

  log(msg) {
    if(typeof msg === 'object' && msg !== null) {
      msg = JSON.stringify(msg);
    }
    this.loggerService.log("socket.service - "+msg);
  }
}
