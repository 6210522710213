import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Room } from 'src/app/data/models/room.model';
import { UserService } from './user.service';
import { LoggerService } from './logger.service';
import { DeviceDetectorService } from 'ngx-device-detector';
declare var $: any;
declare var Janus: any;

@Injectable({
  providedIn: 'root'
})
export class VideoService {

  videoServerUrl = "wss://server1.constellationsonline.eu/jws";
  videoStarted = false;
  libraryInit = false;
  unPublish = false;
  muted = true;
  novideo = false;
  addLocalVideo = false;
  noOfpublishers = 0;
  maxUser = 25;
  noCol = 4;
  secondRowCol = 4;
  freeHole = [];
  maxUsersPerPage = 12;
  localVideoSeq = 0;
  leavedUserArr = [];
  activeUsers = [];
  joinedUsers = [];
  previousJoinedUsers = [];
  bitrates = [];
  mybitrate = 0;
  userSelectedReload = false;
  scrBtnDisabled = true;
  totalJoinedUsers = 0;
  firstPageSelected = true;
  showText = false;

  mystream;
  remoteStream = [];
  therapistStream;
  sfutest;
  opaqueId;
  mypvtid;
  myid;
  janus;
  room: Room;
  displayName;

  feeds = [];
  bitrateTimer = [];

  highBitrate = 200000;
  lowBitrate = 100000;
  isDesktopDevice = false;

  doSimulcast = (this.getQueryStringValue("simulcast") === "yes" || this.getQueryStringValue("simulcast") === "true");
  doSimulcast2 = (this.getQueryStringValue("simulcast2") === "yes" || this.getQueryStringValue("simulcast2") === "true");

  constructor(private http: HttpClient, private userService : UserService, private loggerService: LoggerService, private deviceService: DeviceDetectorService) { }

  /**
  * m-1
  * to initialize video library and library connect to video server
  */
  init(attachPlugin) {
    Janus.init({
      debug: true,
      dependencies: Janus.useDefaultDependencies(),
      callback: () => {
        this.log("video server initializing....");
        this.janus = new Janus({
          server: this.videoServerUrl,
          success: () => {
            this.log("video server initialized");
            this.libraryInit = true;
            if(attachPlugin) {
              this.pluginAttached();
            }
          },
          error: (cause) => {
            this.log("video server initializing failed "+cause);
          },
          destroyed: () => {
            this.log("video server destroyed");
          }
        });
      }
    });
  }

  /**
  * m-2
  * to start video call
  */
  startVideo() {
    this.showConnectingDialog();
    // if(this.janus) {
      this.pluginAttached();
    /* } else {
      this.init(true);
    } */

  }

  
  /**
  * m-3
  * to attach video plugin and create and configure room
  */
  pluginAttached() {
    let startBtn = document.getElementById('video-start-btn');
    let stopBtn = document.getElementById('video-stop-btn');
    this.janus.attach(
      {
        plugin: "janus.plugin.videoroom",
        opaqueId: this.opaqueId,
        success: (pluginHandle) => {
          this.sfutest = pluginHandle;
          this.log("Plugin attached! (" + this.sfutest.getPlugin() + ", id=" + this.sfutest.getId() + ")");
          let user = JSON.parse(localStorage.getItem('user'))
          let therapist = user && user.roles && user.roles[0] == "ROLE_THERAPIST";
          let client = JSON.parse(localStorage.getItem('client'));

          let room = {
            request: "create",
            room: parseInt(this.room.name),
            permanent: false,
            is_private: false,
            publishers: 25,
            fir_freq: 10,
            notify_joining: true,
            audiolevel_ext: true,
            audiolevel_event: true,
            audio_active_packets: 100,
            audio_level_average: 50
            //bitrate: 200000,
            //bitrate_cap: true,
            //videocodec: "vp9"
          };
          this.sfutest.send({ message: room });
          let register = {
            request: "join",
            room: parseInt(this.room.name),
            ptype: "publisher",
            display: therapist ? user.id+"@therapist" : client.id+""
          };
          this.sfutest.send({ message: register });
          setInterval(() => {
            let request = {
              request: "listparticipants",
              room: parseInt(this.room.name)
            };
            this.sfutest.send({ message: request ,  success: (data) => {
              let participants = data.participants;
              participants.forEach(participant => {
                if(!participant.display.includes("therapist") && this.joinedUsers.length>1) {
                  if(this.remoteStream[participant.display]) {
                    this.remoteStream[participant.display].talking = participant.talking;
                  }
                }
              });
            }});
          }, 300);
        },
        webrtcState: (on) => {
          this.log("Janus says our WebRTC PeerConnection is " + (on ? "up" : "down") + " now");
          //$("#videolocal").parent().parent().unblock();
          if(!on)
            return;
          //$('#publish').remove();
          // This controls allows us to override the global room bitrate cap
          //$('#bitrate').parent().parent().removeClass('hide').show();
          //$('#bitrate a').click(function() {
            var id = $(this).attr("id");
            var bitrate = 100000;
            console.log("firefox: "+this.isFirefox());
            if(this.isTherapistUser() || (this.joinedUsers.length<2 && !this.isFirefox())) {
              bitrate = 200000;
            } else if(this.isFirefox()) {
              if(this.joinedUsers.length<2) {
                bitrate = 150000;
              } else {
                bitrate = 75000;
              }
            }

            if(bitrate === 0) {
              this.log("Not limiting bandwidth via REMB");
            } else {
              this.log("Capping bandwidth to " + bitrate + " via REMB");
            }
            this.mybitrate = bitrate;
            //$('#bitrateset').html($(this).html() + '<span class="caret"></span>').parent().removeClass('open');
            this.sfutest.send({ message: { request: "configure", bitrate: bitrate }});
            //return false;
          //});
        },
        onmessage: (msg, jsep) => {
          this.onMessage(msg, jsep);
        },
        onlocalstream: (stream) => {
          this.onLocalStream(stream, startBtn, stopBtn)
        },
        error: (error) => {
          this.log("  -- attach Error attaching plugin..."+error);
        },
        consentDialog: (on) => {
          this.log(" -- attach consentDialog "+on);
        },
        onremotestream: (stream) => {
          this.log(" -- attach onremotestream ");;
        },
        oncleanup: () => {
          this.log(" -- attach oncleanup ");
        },
        detached: () => {
          this.log(" -- attach detached ");
        }
      });
  }

  /**
  * m-4
  * to leave from video room
  */
  leaveRoom() {
    //this.unpublishOwnFeed();
    let haveSession = this.videoStarted;
    if(haveSession) {
      this.showDisConnectingDialog();
      var leave = { request: "leave"};
      this.sfutest.send({ message: leave });
      this.sfutest.hangup();
      this.videoStarted = false;
      this.unPublish = false;
      /* let startBtn = document.getElementById('video-start-btn');
      let stopBtn = (<HTMLInputElement>document.getElementById('video-stop-btn'));
      startBtn.style.display = "block";
      stopBtn.style.display = "none";
      for(var i=1; i<=this.maxUser; i++) {
        var remoteFeed = this.feeds[i];
        if(remoteFeed) {
          $('#video-block-'+remoteFeed.rfindex).remove();
          this.feeds[remoteFeed.rfindex] = null;
          remoteFeed.detach();
        }
      }
      $('#video-block-local').remove();
      this.janus = null;
      this.noOfpublishers = 0;
      this.activeUsers = [];
      stopBtn.disabled = false; */
      this.hideDisConnectingDialog();
    }
  }

  /**
  * m-5
  * to check which type of message coming from janus server and route to different methods
  */
  onMessage(msg, jsep) {
    let event = msg["videoroom"];
    if(event) {
      if(event === "joined") {
        // Publisher/manager created, negotiate WebRTC and attach to existing feeds, if any
        this.myid = msg["id"];
        this.mypvtid = msg["private_id"];
        //this.log("Successfully joined room " + msg["room"] + " with ID " + this.myid);
        this.publishOwnFeed(true);
        // Any new feed to attach to?
        if(msg["publishers"]) {
          var list = msg["publishers"];
          //this.log(list.length);
          this.noOfpublishers = list.length;
          $('#noOfpublishers').val(this.noOfpublishers);
          if(this.noOfpublishers==0) {
            this.addLocalVideo = true;
          }
          this.log("From Own - Got a list of available publishers feeds: ");
          for(var f in list) {
            var id = list[f]["id"];
            var display = list[f]["display"];
            var audio = list[f]["audio_codec"];
            var video = list[f]["video_codec"];
            this.log("  >> [" + id + "] " + display + " (audio: " + audio + ", video: " + video + ")");
            //this.log(list.indexOf(list[f]));
            this.newRemoteFeed(id, display, audio, video, list.indexOf(list[f]), true);
          }
        }
      } else if(event === "destroyed") {
        // The room has been destroyed
        this.log("The room has been destroyed!");
      } else if(event === "event") {
        // Any new feed to attach to?
        if(msg["publishers"]) {
          var list = msg["publishers"];
          this.log("publishers");
          //this.log(list.length);
          this.noOfpublishers += list.length;
          //this.log(this.noOfpublishers);
          //this.log((this.noOfpublishers==2));
          $('#noOfpublishers').val(this.noOfpublishers);
          this.log("Got a list of available publishers feeds: ");
          for(var f in list) {
            var id = list[f]["id"];
            var display = list[f]["display"];
            var audio = list[f]["audio_codec"];
            var video = list[f]["video_codec"];
            //this.log("  >> [" + id + "] " + display + " (audio: " + audio + ", video: " + video + ")");
            this.newRemoteFeed(id, display, audio, video, list.indexOf(list[f]), false);
          }
        } else if(msg["leaving"]) {
          // One of the publishers has gone away?
          var leaving = msg["leaving"];
          this.log("Publisher left: " + leaving);
          var remoteFeed = null;
          for(var i=1; i<=this.maxUser; i++) {
            if(this.feeds[i] && this.feeds[i].rfid == leaving) {
              remoteFeed = this.feeds[i];
              break;
            }
          }
          if(remoteFeed != null) {
            Janus.debug("Feed " + remoteFeed.rfid + " (" + remoteFeed.rfdisplay + ") has left the room, detaching");
            $('#remote'+remoteFeed.rfindex).empty().hide();
            $('#videoremote'+remoteFeed.rfindex).empty();
            this.feeds[remoteFeed.rfindex] = null;
            remoteFeed.detach();
          }
        } else if(msg["unpublished"]) {
          // One of the publishers has unpublished?
          var unpublished = msg["unpublished"];
          Janus.log("Publisher left: " + unpublished);
          if(unpublished === 'ok') {
            // That's us
            this.sfutest.hangup();
            if(this.isTherapistUser()) {
              this.therapistStream = null;
            } else {
              this.mystream = null;
            }
            return;
          }
          var remoteFeed = null;
          for(var i=1; i<=this.maxUser; i++) {
            if(this.feeds[i] && this.feeds[i].rfid == unpublished) {
              remoteFeed = this.feeds[i];
              break;
            }
          }
          if(remoteFeed != null) {
            this.log("Feed " + remoteFeed.rfid + " (" + remoteFeed.rfdisplay + ") has left the room, detaching");
            $('#remote'+remoteFeed.rfindex).empty().hide();
            $('#videoremote'+remoteFeed.rfindex).empty();
            this.feeds[remoteFeed.rfindex] = null;
            remoteFeed.detach();
          }
        } else if(msg["error"]) {
          if(msg["error_code"] === 426) {
            // This is a "no such room" error: give a more meaningful description
            this.log(
              "Apparently room" + this.room.name + " (the one this demo uses as a test room) " +
              "does not exist... Do you have an updated janus.plugin.videoroom.jcfg " +
              "configuration file? If not, make sure you copy the details of room " + this.room.name +
              " from that sample in your current configuration file, then restart Janus and try again."
            );
          } else {
            this.log(msg["error"]);
          }
        }
      }
    }
    if(jsep) {
      this.sfutest.handleRemoteJsep({ jsep: jsep });
      // Check if any of the media we wanted to publish has
      // been rejected (e.g., wrong or unsupported codec)
      var audio = msg["audio_codec"];
      if(this.mystream && this.mystream.getAudioTracks() && this.mystream.getAudioTracks().length > 0 && !audio) {
        // Audio has been rejected
        this.log("Our audio stream has been rejected, viewers won't hear us");
      }
      var video = msg["video_codec"];
      if(this.mystream && this.mystream.getVideoTracks() && this.mystream.getVideoTracks().length > 0 && !video) {
        // Video has been rejected
        this.log("Our video stream has been rejected, viewers won't see us");
        // Hide the webcam video
       /*  $('#myvideo').hide();
        $('#videolocal').append(
          '<div class="no-video-container">' +
            '<i class="fa fa-video-camera fa-5 no-video-icon" style="height: 100%;"></i>' +
            '<span class="no-video-text" style="font-size: 16px;">Video rejected, no webcam</span>' +
          '</div>'); */
      }
    }
  }

  /**
  * m-6
  * to attach local camera video to video element to see own camera
  */
  onLocalStream(stream, startBtn, stopBtn) {
    this.log(" ::: Got a local stream ::: ");
    this.videoStarted = true;
    startBtn.style.display = "none";
    startBtn.disabled = false;
    stopBtn.style.display = "block";
    if(!this.isDesktopDevice) {
      stopBtn.style.display = "initial";
    }
    this.hideConnectingDialog();
    if(this.isTherapistUser()) {
      this.therapistStream = stream;
    } else {
      this.mystream = stream;
    }
    let therapist = JSON.parse(localStorage.getItem('therapist'));
    therapist = therapist && therapist.roles && therapist.roles[0] == "ROLE_THERAPIST";
    //this.addVideoBlock('therapist', "local", this.displayName);
    this.setLocalVideoProp(stream);
    $('#play-area-move').click();
    //$('#video-block-local').removeClass('col-md-'+this.noCol).removeClass('participant-2').addClass('col-md-12');
    //this.setSelectedVideo();
  }

  setLocalVideoProp(stream) {
    let client = JSON.parse(localStorage.getItem('client'));
    let rfindex = this.isTherapistUser() ? 'therapist' : client.id;
    //Janus.attachMediaStream($('#remotevideo'+rfindex).get(0), stream);
    $('#remotevideo'+rfindex).get(0).muted = "muted";
    var videoTracks = stream.getVideoTracks();
    /* if(!videoTracks || videoTracks.length === 0) {
      // No remote video
      $('#videoremote'+rfindex+ ' .user-container').hide();
      $('#remotevideo'+rfindex).hide();
      $('#videoremote'+rfindex+ ' .no-video-container').removeClass('hide').show();
    } else {
      $('#videoremote'+rfindex+ ' .user-container').hide();
      $('#videoremote'+rfindex+ ' .no-video-container').hide();
      $('#remotevideo'+rfindex).removeClass('hide').show();
    } */
    $('#mute').show();
  }

  /**
  * m-7
  * to publish local camera to other video room users
  */
  publishOwnFeed(useAudio) {
	  // Publish our stream
    this.sfutest.createOffer(
		{
			// Add data:true here if you want to publish datachannels as well
			media: { audioRecv: false, videoRecv: false, audioSend: useAudio, videoSend: true },
			simulcast: this.doSimulcast,
			simulcast2: this.doSimulcast2,
			success: (jsep) => {
        this.log("Got publisher SDP! ");
				var publish = { request: "configure", audio: useAudio, video: true };
        this.sfutest.send({ message: publish, jsep: jsep });
        let stopBtn = (<HTMLInputElement>document.getElementById('video-stop-btn'));
        if(stopBtn) {
          stopBtn.disabled = false;
        }
        if(this.muted) {
          setTimeout(() => this.toggleMute(), (this.unPublish ? 1000 : 2000));
        }
        this.unPublish = false;
			},
			error: (error) => {
				this.log("WebRTC error: "+error);
				if(useAudio) {
					 //this.publishOwnFeed(false);
				} else {
					this.log("WebRTC error... " + error.message);
				}
			}
		});
  }

  /**
  * m-8
  * to attach remote video and route to other methods
  */
  newRemoteFeed(id, display, audio, video,idx, fromAll) {
    // A new feed has been published, create a new plugin handle and attach to it as a subscriber
    let remoteFeed = null;
    this.janus.attach(
      {
        plugin: "janus.plugin.videoroom",
        opaqueId: this.opaqueId,
        success: (pluginHandle) => {
          remoteFeed = pluginHandle;
          this.remoteFeedPluginHandle(id, video, remoteFeed);
        },
        error: (error) => {
          this.log("  -- Error attaching plugin... "+error);
        },
        onmessage: (msg, jsep) => {
          this.onRemoteMessage(msg, jsep, remoteFeed);
        },
        iceState: (state) => {
          this.log("ICE state of this WebRTC PeerConnection (feed #" + remoteFeed.rfindex + ") changed to " + state);
        },
        webrtcState: (on) => {
          this.log("Janus says this WebRTC PeerConnection (feed #" + remoteFeed.rfindex + ") is " + (on ? "up" : "down") + " now");
        },
        onremotestream: (stream) => {
          this.onRemoteStream(remoteFeed, stream, idx, fromAll);
        },
        oncleanup: () => {
          this.onRemoteCleanUp(id, remoteFeed);
        }
      });
  }

  /**
  * m-9
  * to attach remote video to video element to see other users 
  */
  remoteFeedPluginHandle(id, video, remoteFeed) {
    remoteFeed.simulcastStarted = false;
    //this.log("Plugin attached! (" + remoteFeed.getPlugin() + ", id=" + remoteFeed.getId() + ")");
    //this.log("  -- This is a subscriber");
    // We wait for the plugin to send us an offer
    var subscribe = {
      request: "join",
      room: parseInt(this.room.name),
      ptype: "subscriber",
      feed: id,
      private_id: this.mypvtid
    };
    this.log("Publisher is using " + video);
    if(Janus.webRTCAdapter.browserDetails.browser === "safari" &&
        (video === "vp9" || (video === "vp8" && !Janus.safariVp8))) {
      if(video)
        video = video.toUpperCase()
        this.log("Publisher is using " + video + ", but Safari doesn't support it: disabling video");
      subscribe["offer_video"] = false;
    }
    remoteFeed.videoCodec = video;
    remoteFeed.send({ message: subscribe });
  }

  /**
  * m-10
  * to listen remote message and route to other methods
  */
  onRemoteMessage(msg, jsep, remoteFeed) {
    //this.log(remoteFeed);
    var event = msg["videoroom"];
    if(msg["error"]) {
      this.log(msg["error"]);
    } else if(event) {
      if(event === "attached") {
        // Subscriber created and attached
        for(var i=1;i<=this.maxUser;i++) {
          if(!this.feeds[i]) {
            this.feeds[i] = remoteFeed;
            remoteFeed.rfindex = i;
            break;
          }
        }
        remoteFeed.rfid = msg["id"];
        remoteFeed.rfdisplay = msg["display"];
        if(!remoteFeed.spinner) {
          //var target = document.getElementById('videoremote'+remoteFeed.rfindex);
          //remoteFeed.spinner = new Spinner({top:100}).spin(target);
        } else {
          remoteFeed.spinner.spin();
        }
        //this.log("Successfully attached to feed " + remoteFeed.rfid + " (" + remoteFeed.rfdisplay + ") in room " + msg["room"]);
        //$('#remote'+remoteFeed.rfindex).removeClass('hide').html(remoteFeed.rfdisplay).show();
      } else if(event === "event") {
        // Check if we got an event on a simulcast-related event from this publisher
        var substream = msg["substream"];
        var temporal = msg["temporal"];
        if((substream !== null && substream !== undefined) || (temporal !== null && temporal !== undefined)) {
          if(!remoteFeed.simulcastStarted) {
            remoteFeed.simulcastStarted = true;
            // Add some new buttons
            this.addSimulcastButtons(remoteFeed.rfindex, remoteFeed.videoCodec === "vp8" || remoteFeed.videoCodec === "h264");
          }
          // We just received notice that there's been a switch, update the buttons
          this.updateSimulcastButtons(remoteFeed.rfindex, substream, temporal);
        }
      } else {
        // What has just happened?
      }
    }
    if(jsep) {
      //this.log("Handling SDP as well...", jsep);
      // Answer and attach
      remoteFeed.createAnswer(
        {
          jsep: jsep,
          // Add data:true here if you want to subscribe to datachannels as well
          // (obviously only works if the publisher offered them in the first place)
          media: { audioSend: false, videoSend: false },	// We want recvonly audio/video
          success: (jsep) => {
            Janus.debug("Got SDP!", jsep);
            var body = { request: "start", room: parseInt(this.room.name) };
            remoteFeed.send({ message: body, jsep: jsep });
          },
          error: (error) => {
            this.log("WebRTC error: "+error);
          }
        });
    }
  }

  /**
  * m-11
  * to attach remote video to view
  */
  onRemoteStream(remoteFeed, stream, idx, fromAll) {
    if(!remoteFeed.rfdisplay.includes("@therapist")) {
      this.remoteStream[remoteFeed.rfdisplay] = stream;
    } else {
      this.therapistStream = stream;
    }
    Janus.debug("Remote feed #" + remoteFeed.rfindex + ", stream:", stream);
    var addButtons = false;
    this.log(remoteFeed.rfindex);
    //this.setSelectedVideo();
    let rfindex = remoteFeed.rfdisplay.includes("@therapist") ? 'therapist' : remoteFeed.rfdisplay;
    //Janus.attachMediaStream($('#remotevideo'+rfindex).get(0), stream);
    var videoTracks = stream.getVideoTracks();
    console.log(rfindex);
    if(Janus.webRTCAdapter.browserDetails.browser === "chrome" || Janus.webRTCAdapter.browserDetails.browser === "firefox" ||
						Janus.webRTCAdapter.browserDetails.browser === "safari") {
					this.bitrateTimer[rfindex] = setInterval(() => {
						// Display updated bitrate, if supported
            var bitrate = remoteFeed.getBitrate();
						this.bitrates[rfindex] = bitrate;
					}, 1000);
				}
   /*  if(!videoTracks || videoTracks.length === 0) {
      // No remote video
      $('#videoremote'+rfindex+ ' .user-container').hide();
      $('#remotevideo'+rfindex).hide();
      $('#videoremote'+rfindex+ ' .no-video-container').removeClass('hide').show();
    } else {
      $('#videoremote'+rfindex+ ' .user-container').hide();
      $('#videoremote'+rfindex+ ' .no-video-container').hide();
      $('#remotevideo'+rfindex).removeClass('hide').show();
    } */
  }

  reBindRemoteStream(userId) {
    if(this.remoteStream[userId]) {
      Janus.attachMediaStream($('#remotevideo'+userId).get(0), this.remoteStream[userId]);
    }
  }

  /**
  * m-12
  * to remove remote view video when remote video stop or leave
  */
  onRemoteCleanUp(id, remoteFeed) {
    this.log(" ::: Got a cleanup notification (remote feed " + id + ") :::");
    if(!remoteFeed.rfdisplay.includes("@therapist")) {
      this.remoteStream[remoteFeed.rfdisplay] = null;
    } else {
      this.therapistStream = null;
    }
    remoteFeed.simulcastStarted = false;
    let rfindex = remoteFeed.rfdisplay.includes("@therapist") ? 'therapist' : remoteFeed.rfdisplay;
    if(this.bitrateTimer[rfindex] !== null && this.bitrateTimer[rfindex] !== null)
					clearInterval(this.bitrateTimer[rfindex]);
					this.bitrateTimer[rfindex] = null;
    if(!this.videoStarted) {
      return;
    }
    /* if($('#video-block-'+remoteFeed.rfindex).length > 0) {
      for(let i=0;i<this.activeUsers.length;i++) {
        if(this.activeUsers[i].rfindex == remoteFeed.rfindex) {
          this.activeUsers[i].splice(i, 1);
        }
      }
      if(this.noOfpublishers>this.maxUsersPerPage) {
        this.setLeaveUser(remoteFeed.rfindex);
      }
      $('#video-block-'+remoteFeed.rfindex).remove();
      $('#simulcast'+remoteFeed.rfindex).remove();
      this.noOfpublishers--;
      if(this.noOfpublishers <= 1){
        $('.card').css("margin-left", "21.05%");
        $('.card').css("max-width", "57.05vw");
        $('#row-therapist').css("margin-left", "20vw").css("min-height","32vh");
        $('#row-therapist .col-md-10').removeClass('col-md-12').addClass('col-md-12');
        $('#row-1 .col-md-4').css("margin-left","0.4vw").css("max-width","30vw");
        $('#row-1 .col-md-4').addClass('col-md-12').removeClass('col-md-4');
        $('#row-1 .panel-body').css("height","32vh").css("margin-top","0");
        $('#row-1').css("padding-left", "0");
      }
      this.log(remoteFeed);
      this.reArrangeInPage(remoteFeed.rfindex);
    } */
  }

  // Helper to parse query string
  getQueryStringValue(name) {
    name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
    let regex = new RegExp("[\\?&]" + name + "=([^&#]*)"),
      results = regex.exec(location.search);
    return results === null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
  }

  // Helpers to create Simulcast-related UI, if enabled
  addSimulcastButtons(feed, temporal) {
    var index = feed;
    $('#remote'+index).parent().append(
      '<div id="simulcast'+index+'" class="btn-group-vertical btn-group-vertical-xs pull-right">' +
      '	<div class"row">' +
      '		<div class="btn-group btn-group-xs" style="width: 100%">' +
      '			<button id="sl'+index+'-2" type="button" class="btn btn-primary" data-toggle="tooltip" title="Switch to higher quality" style="width: 33%">SL 2</button>' +
      '			<button id="sl'+index+'-1" type="button" class="btn btn-primary" data-toggle="tooltip" title="Switch to normal quality" style="width: 33%">SL 1</button>' +
      '			<button id="sl'+index+'-0" type="button" class="btn btn-primary" data-toggle="tooltip" title="Switch to lower quality" style="width: 34%">SL 0</button>' +
      '		</div>' +
      '	</div>' +
      '	<div class"row">' +
      '		<div class="btn-group btn-group-xs hide" style="width: 100%">' +
      '			<button id="tl'+index+'-2" type="button" class="btn btn-primary" data-toggle="tooltip" title="Cap to temporal layer 2" style="width: 34%">TL 2</button>' +
      '			<button id="tl'+index+'-1" type="button" class="btn btn-primary" data-toggle="tooltip" title="Cap to temporal layer 1" style="width: 33%">TL 1</button>' +
      '			<button id="tl'+index+'-0" type="button" class="btn btn-primary" data-toggle="tooltip" title="Cap to temporal layer 0" style="width: 33%">TL 0</button>' +
      '		</div>' +
      '	</div>' +
      '</div>'
    );
    // Enable the simulcast selection buttons
    $('#sl' + index + '-0').removeClass('btn-primary btn-success').addClass('btn-primary')
      .unbind('click').click(() => {
        //this.log("Switching simulcast substream, wait for it... (lower quality)", null, {timeOut: 2000});
        if(!$('#sl' + index + '-2').hasClass('btn-success'))
          $('#sl' + index + '-2').removeClass('btn-primary btn-info').addClass('btn-primary');
        if(!$('#sl' + index + '-1').hasClass('btn-success'))
          $('#sl' + index + '-1').removeClass('btn-primary btn-info').addClass('btn-primary');
        $('#sl' + index + '-0').removeClass('btn-primary btn-info btn-success').addClass('btn-info');
        this.feeds[index].send({ message: { request: "configure", substream: 0 }});
		});
    $('#sl' + index + '-1').removeClass('btn-primary btn-success').addClass('btn-primary')
      .unbind('click').click(() => {
        //this.log("Switching simulcast substream, wait for it... (normal quality)", null, {timeOut: 2000});
        if(!$('#sl' + index + '-2').hasClass('btn-success'))
          $('#sl' + index + '-2').removeClass('btn-primary btn-info').addClass('btn-primary');
        $('#sl' + index + '-1').removeClass('btn-primary btn-info btn-success').addClass('btn-info');
        if(!$('#sl' + index + '-0').hasClass('btn-success'))
          $('#sl' + index + '-0').removeClass('btn-primary btn-info').addClass('btn-primary');
        this.feeds[index].send({ message: { request: "configure", substream: 1 }});
		});
    $('#sl' + index + '-2').removeClass('btn-primary btn-success').addClass('btn-primary')
      .unbind('click').click(() => {
        //this.log("Switching simulcast substream, wait for it... (higher quality)", null, {timeOut: 2000});
        $('#sl' + index + '-2').removeClass('btn-primary btn-info btn-success').addClass('btn-info');
        if(!$('#sl' + index + '-1').hasClass('btn-success'))
          $('#sl' + index + '-1').removeClass('btn-primary btn-info').addClass('btn-primary');
        if(!$('#sl' + index + '-0').hasClass('btn-success'))
          $('#sl' + index + '-0').removeClass('btn-primary btn-info').addClass('btn-primary');
        this.feeds[index].send({ message: { request: "configure", substream: 2 }});
		});
    if(!temporal)	// No temporal layer support
      return;
    $('#tl' + index + '-0').parent().removeClass('hide');
    $('#tl' + index + '-0').removeClass('btn-primary btn-success').addClass('btn-primary')
      .unbind('click').click(() => {
        //this.log("Capping simulcast temporal layer, wait for it... (lowest FPS)", null, {timeOut: 2000});
        if(!$('#tl' + index + '-2').hasClass('btn-success'))
          $('#tl' + index + '-2').removeClass('btn-primary btn-info').addClass('btn-primary');
        if(!$('#tl' + index + '-1').hasClass('btn-success'))
          $('#tl' + index + '-1').removeClass('btn-primary btn-info').addClass('btn-primary');
        $('#tl' + index + '-0').removeClass('btn-primary btn-info btn-success').addClass('btn-info');
        this.feeds[index].send({ message: { request: "configure", temporal: 0 }});
		});
    $('#tl' + index + '-1').removeClass('btn-primary btn-success').addClass('btn-primary')
      .unbind('click').click(() => {
        //this.log("Capping simulcast temporal layer, wait for it... (medium FPS)", null, {timeOut: 2000});
        if(!$('#tl' + index + '-2').hasClass('btn-success'))
          $('#tl' + index + '-2').removeClass('btn-primary btn-info').addClass('btn-primary');
        $('#tl' + index + '-1').removeClass('btn-primary btn-info').addClass('btn-info');
        if(!$('#tl' + index + '-0').hasClass('btn-success'))
          $('#tl' + index + '-0').removeClass('btn-primary btn-info').addClass('btn-primary');
        this.feeds[index].send({ message: { request: "configure", temporal: 1 }});
		});
    $('#tl' + index + '-2').removeClass('btn-primary btn-success').addClass('btn-primary')
      .unbind('click').click(() => {
        //this.log("Capping simulcast temporal layer, wait for it... (highest FPS)", null, {timeOut: 2000});
        $('#tl' + index + '-2').removeClass('btn-primary btn-info btn-success').addClass('btn-info');
        if(!$('#tl' + index + '-1').hasClass('btn-success'))
          $('#tl' + index + '-1').removeClass('btn-primary btn-info').addClass('btn-primary');
        if(!$('#tl' + index + '-0').hasClass('btn-success'))
          $('#tl' + index + '-0').removeClass('btn-primary btn-info').addClass('btn-primary');
        this.feeds[index].send({ message: { request: "configure", temporal: 2 }});
		});
  }

  updateSimulcastButtons(feed, substream, temporal) {
    // Check the substream
    var index = feed;
    if(substream === 0) {
      //this.log("Switched simulcast substream! (lower quality)", null, {timeOut: 2000});
      $('#sl' + index + '-2').removeClass('btn-primary btn-success').addClass('btn-primary');
      $('#sl' + index + '-1').removeClass('btn-primary btn-success').addClass('btn-primary');
      $('#sl' + index + '-0').removeClass('btn-primary btn-info btn-success').addClass('btn-success');
    } else if(substream === 1) {
      //this.log("Switched simulcast substream! (normal quality)", null, {timeOut: 2000});
      $('#sl' + index + '-2').removeClass('btn-primary btn-success').addClass('btn-primary');
      $('#sl' + index + '-1').removeClass('btn-primary btn-info btn-success').addClass('btn-success');
      $('#sl' + index + '-0').removeClass('btn-primary btn-success').addClass('btn-primary');
    } else if(substream === 2) {
      //this.log("Switched simulcast substream! (higher quality)", null, {timeOut: 2000});
      $('#sl' + index + '-2').removeClass('btn-primary btn-info btn-success').addClass('btn-success');
      $('#sl' + index + '-1').removeClass('btn-primary btn-success').addClass('btn-primary');
      $('#sl' + index + '-0').removeClass('btn-primary btn-success').addClass('btn-primary');
    }
    // Check the temporal layer
    if(temporal === 0) {
      //this.log("Capped simulcast temporal layer! (lowest FPS)", null, {timeOut: 2000});
      $('#tl' + index + '-2').removeClass('btn-primary btn-success').addClass('btn-primary');
      $('#tl' + index + '-1').removeClass('btn-primary btn-success').addClass('btn-primary');
      $('#tl' + index + '-0').removeClass('btn-primary btn-info btn-success').addClass('btn-success');
    } else if(temporal === 1) {
      //this.log("Capped simulcast temporal layer! (medium FPS)", null, {timeOut: 2000});
      $('#tl' + index + '-2').removeClass('btn-primary btn-success').addClass('btn-primary');
      $('#tl' + index + '-1').removeClass('btn-primary btn-info btn-success').addClass('btn-success');
      $('#tl' + index + '-0').removeClass('btn-primary btn-success').addClass('btn-primary');
    } else if(temporal === 2) {
      //this.log("Capped simulcast temporal layer! (highest FPS)", null, {timeOut: 2000});
      $('#tl' + index + '-2').removeClass('btn-primary btn-info btn-success').addClass('btn-success');
      $('#tl' + index + '-1').removeClass('btn-primary btn-success').addClass('btn-primary');
      $('#tl' + index + '-0').removeClass('btn-primary btn-success').addClass('btn-primary');
    }
  }

  /**
  * m-13
  * to unpublish local video without stopping remote video
  */
  unpublishOwnFeed() {
    // Unpublish our stream
    var unpublish = { request: "unpublish" };
    this.sfutest.send({ message: unpublish });
  }

  /**
  * m-14
  * to toggle local mic
  */
  toggleMute() {
    var muted = this.sfutest.isAudioMuted();
    this.log((muted ? "Unmuting" : "Muting") + " local stream...");
    if(muted)
      this.sfutest.unmuteAudio();
    else
      this.sfutest.muteAudio();
    this.muted = !muted;
  }

  toggleVideo() {
    var video = this.sfutest.isVideoMuted();
    this.log((video ? "stop video" : "start video") + " local stream...");
  }

  addVideoBlock(rowId, videoBlockId, displayName) {
    if(videoBlockId=="local" || videoBlockId=="therapist" || videoBlockId==1 || $('#row-'+rowId+' #video-block-'+(videoBlockId-1)).length === 0) {
      if($('video-block-'+videoBlockId)) {
        $('#video-block-'+videoBlockId).remove();
      }
      $('#row-'+rowId).append(this.getVideoElements(rowId, videoBlockId, displayName));
    } else {
      if($('video-block-'+videoBlockId)) {
        $('#video-block-'+videoBlockId).remove();
      }
      $('#video-block-'+(videoBlockId-1)).after(this.getVideoElements(rowId, videoBlockId, displayName));
    }
  }

  getVideoElements(rowId, videoBlockId, displayName) {
    //this.log(this.firstRowCol);
    let title = "";
    let muteBtn = '<button class="btn btn-xs" id="mute" style="position: absolute; bottom: 0px;left: 0px;margin: 15px;z-index: 1;font-size: 20px;background-color: transparent;border: none;color: green"><i class="fa fa-microphone"></i></button>';
    let videoBtn = '<button class="btn btn-xs" id="toggle-video" style="position: absolute; bottom: 0px; right: 0px; margin: 15px;z-index: 1;font-size: 20px;background-color: transparent;border: none;color: green;display:none"><i class="fa fa-video-camera"></i></button>';
    if(videoBlockId=="local") {
      title = '<h3 class="panel-title">You (<span class="label label-primary hide" id="publisher">'+displayName+'</span>)</h3>';
    } else {
      title = '<h3 class="panel-title"><span class="label label-info hide" id="remote'+videoBlockId+'">'+displayName+'</span></h3>';
    }
    return '<div id="video-block-'+videoBlockId+'" class="col-md-'+this.noCol+'">' +
              '<div class="panel panel-default" style="height: 100%;'+(rowId==2?'margin-top: 1vh;':'')+'">' +
                '<div class="panel-heading" style="z-index: 1;">' +
                  title +
                '</div>' +
                '<div class="panel-body" id="'+(videoBlockId=="local" ? "videolocal" : "videoremote"+videoBlockId) +'" style="'+(rowId=="therapist" || this.noOfpublishers==1 ? 'height:32vh;' : 'height:20vh;margin-top: 3vh;')+'">' +
                  '<video class="rounded centered" id="'+(videoBlockId=="local" ? "myvideo" : "remotevideo"+videoBlockId) +'" width="100%" height="100%" autoplay="" playsinline=""></video>' +
                  (videoBlockId=="local" ? muteBtn : '') +
                  (videoBlockId=="local" ? videoBtn : '') +
                  '<div class="no-video-container hide">' +
                    '<i class="fa fa-video-camera fa-5 no-video-icon" style="display: table-cell;vertical-align: middle;padding-bottom: 10px;"></i>' +
                    '<span class="no-video-text">No webcam available</span>' +
                  '</div>' +
                '</div>' +
              '</div>' +
            '</div>';
  }

  changeVideoGroup() {
    if(this.noCol==3) {
      $('.video-room-block .col-md-'+4).removeClass('col-md-'+4).addClass('col-md-'+this.noCol);
      $('.video-room-block .col-md-'+this.noCol+' .panel-body').css("min-height", "14vh").css("max-height", "14vh");
      $('.video-room-block .col-md-'+this.noCol+' video').css("min-height", "14vh").css("max-height", "14vh");
      $('.video-room-block .col-md-'+this.noCol+' .no-video-container').css("min-height", "14vh").css("max-height", "14vh");
      $('.video-room-block-next .col-md-'+4).removeClass('col-md-'+4).addClass('col-md-'+this.noCol);
      $('.video-room-block-next .col-md-'+this.noCol+' .panel-body').css("min-height", "14vh").css("max-height", "14vh");
      $('.video-room-block-next .col-md-'+this.noCol+' video').css("min-height", "14vh").css("max-height", "14vh");
      $('.video-room-block-next .col-md-'+this.noCol+' .no-video-container').css("min-height", "14vh").css("max-height", "14vh");
      let nextPage = $('#row-2').children();
      for(var i=0; i<nextPage.length; i++) {
        $('#row-1').append(nextPage[i]);
      }
      $('.video-room-block').css("display", "block");
      $('.video-room-block-next').css("display", "none");
    } else {
      $('.video-room-block .col-md-'+3).removeClass('col-md-'+3).addClass('col-md-'+this.noCol);
      $('.video-room-block .col-md-'+this.noCol+' .panel-body').css("min-height", "20vh").css("max-height", "20vh");
      $('.video-room-block .col-md-'+this.noCol+' video').css("min-height", "20vh").css("max-height", "20vh");
      $('.video-room-block .col-md-'+this.noCol+' .no-video-container').css("min-height", "20vh").css("max-height", "20vh");
      $('.video-room-block-next .col-md-'+3).removeClass('col-md-'+3).addClass('col-md-'+this.noCol);
      $('.video-room-block-next .col-md-'+this.noCol+' .panel-body').css("min-height", "20vh").css("max-height", "20vh");
      $('.video-room-block-next .col-md-'+this.noCol+' video').css("min-height", "20vh").css("max-height", "20vh");
      $('.video-room-block-next .col-md-'+this.noCol+' .no-video-container').css("min-height", "20vh").css("max-height", "20vh");
      let nextPage = $('#row-1').children();
      for(var i=this.maxUsersPerPage; i<nextPage.length; i++) {
        $('#row-2').append(nextPage[i]);
      }
      $('.video-room-block').css("display", "block");
      $('.video-room-block-next').css("display", "none");
    }
  }

  changeVideoScreen() {
    let nextPage = $('#row-1').children();
    for(var i=this.maxUsersPerPage; i<nextPage.length; i++) {
      $('#row-2').append(nextPage[i]);
      this.noCol = 4;
      $('.video-room-block .col-md-'+3).removeClass('col-md-'+3).addClass('col-md-'+this.noCol);
      $('.video-room-block .col-md-'+this.noCol+' .panel-body').css("min-height", "20vh").css("max-height", "20vh");
      $('.video-room-block .col-md-'+this.noCol+' video').css("min-height", "20vh").css("max-height", "20vh");
      $('.video-room-block .col-md-'+this.noCol+' .no-video-container').css("min-height", "20vh").css("max-height", "20vh");
      $('.video-room-block-next .col-md-'+3).removeClass('col-md-'+3).addClass('col-md-'+this.noCol);
      $('.video-room-block-next .col-md-'+this.noCol+' .panel-body').css("min-height", "20vh").css("max-height", "20vh");
      $('.video-room-block-next .col-md-'+this.noCol+' video').css("min-height", "20vh").css("max-height", "20vh");
      $('.video-room-block-next .col-md-'+this.noCol+' .no-video-container').css("min-height", "20vh").css("max-height", "20vh");
      $('.video-room-block').css("display", "none");
      $('.video-room-block-next').css("display", "block");
    }
    if($('.video-room-block-next').css("display")=="none") {
      $('.video-room-block').css("display", "none");
      $('.video-room-block-next').css("display", "block");
    } else {
      $('.video-room-block').css("display", "block");
      $('.video-room-block-next').css("display", "none");
    }
  }

  reArrangeInPage(rfindex) {
    if($('#row-1').children().length<this.maxUsersPerPage) {
      let nextPage = $('#row-2').children();
      if(nextPage.length > 0) {
        $('#row-1').append(nextPage[0]);
      }
    }
  }

  setLeaveUser(rfindex) {
    let page1 = $('#row-1').children();
    for(let i=0;i<page1.length;i++) {
      this.log(page1[i].id);
      if(page1[i].id=='video-block-'+rfindex) {
        let place = i+1;
        this.leavedUserArr.push(rfindex);
        break;
      }
    }
    this.log(this.leavedUserArr.toString());
  }

  showConnectingDialog() {
    document.getElementById('openDialogBtn').click();
  }

  hideConnectingDialog() {
    document.getElementById('closeDialogBtn').click();
  }

  showDisConnectingDialog() {
    document.getElementById('openDisDialogBtn').click();
  }

  hideDisConnectingDialog() {
    document.getElementById('closeDisDialogBtn').click();
  }

  getLocalVideoDisplayName() {
    if($("#publisher").length>0) {
      let ele = (<HTMLInputElement>$("#publisher"))[0];
      return ele.innerHTML;
    }
  }

  isTherapistUser() {
    let user = JSON.parse(localStorage.getItem('user'));
    if(user && user.roles && user.roles[0] == 'ROLE_THERAPIST') {
      return true;
    }
    return false;
  }

  isMyVideoActive() {
    let myVideoActive = localStorage.getItem('myVideoActive');
    if(myVideoActive==null) {
      localStorage.setItem('myVideoActive', '0');
      myVideoActive = localStorage.getItem('myVideoActive');
    }
    return myVideoActive=='1';
  }

  setMyVideoActive() {
    localStorage.setItem('myVideoActive', '1');
  }

  setVideoVisible() {
    console.log("video visible clicked");
    /* this.remoteStream.forEach((stream)=> {
      console.log(stream);
      //this.sfutest.send({ message: { request: "unsubscribe" }});
      let videoTrack = stream.getVideoTracks();
      if (videoTrack.length > 0) {
        stream.removeTrack(videoTrack[0]);
      }
    }); */
    this.feeds.forEach((feed)=> {
      console.log(feed.rfdisplay);
      if(!feed.rfdisplay.includes("therapist")) {
        feed.detach();
      }
    });
  }

  setBitrate(bitrate) {
    if(!this.isTherapistUser()) {
      let bitrateCap = bitrate;
      //this.sfutest.send({ message: { request: "edit", room: parseInt(this.room.name), new_bitrate: 80 }});
      let client = JSON.parse(localStorage.getItem('client'));
      let myId = client.id;
      for(let i=0;i<this.joinedUsers.length;i++) {
        if(myId==this.joinedUsers[i].id && this.joinedUsers[i].selected) {
          this.log("setting high bitrate");
          bitrateCap = this.highBitrate;
        }
      }
      if(this.mybitrate!=bitrateCap) {
        this.log("setting bitrate");
        this.sfutest.send({ message: { request: "configure", bitrate: bitrateCap }});
        this.mybitrate = bitrateCap;
      }
    }
  }

  destroyPlugin() {
    if(this.janus) {
      this.janus.destroy({
        plugin: "janus.plugin.videoroom",
        opaqueId: this.opaqueId,
        success: (pluginHandle) => {
          this.log(" -- destroyPlugin success ");
          this.janus = null;
        },
        error: (error) => {
          this.log("  -- destroyPlugin success "+error);
          this.log(error);
        },
      });
    }
  }

  isFirefox() {
    return this.deviceService.browser=='Firefox';
  }

  log(msg) {
    if(typeof msg === 'object' && msg !== null) {
      msg = JSON.stringify(msg);
    }
    this.loggerService.log("video.service - "+msg);
  }
}
