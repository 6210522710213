import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { auth } from 'firebase/app';
import { Observable, from } from 'rxjs';
import { HttpClient } from '@angular/common/http';

export interface ISignInCredentials {
  email: string;
  password: string;
}

export interface ICreateCredentials {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
}

export interface IPasswordReset {
  code: string;
  newPassword: string;
}

@Injectable({ providedIn: 'root' })
export class AuthService {

  constructor(private afAuth: AngularFireAuth, private http : HttpClient) { }

  signIn(credentials: ISignInCredentials): Observable<any> {
    return this.http.post<any>('/api/authentication/login',credentials);
  }

  signOut(userId) {
    if(document.getElementById('video-stop-btn') && document.getElementById('video-stop-btn').style.display!="none") {
      document.getElementById('video-stop-btn').click();
    }
    localStorage.removeItem('user');
    localStorage.removeItem('token');
    localStorage.removeItem('secret_code');
    this.http.post<any>('/api/authentication/logout/'+userId,null).subscribe(data => {
      console.log(data);
    }, error => {
      console.log(error);
    });
    return from(this.afAuth.auth.signOut());
  }

  isTheripstLoggedIn(userId): Observable<any> {
    return this.http.post<any>('/api/authentication/isTheripstLoggedIn/'+userId,null);
  }

  sessionOutPlayArea(userId) {
    return this.http.post<any>('/api/authentication/sessionOut/'+userId,null);
  }

  register(credentials: ICreateCredentials) {
    return  this.http.post<any>('/api/authentication/register',credentials);
  }

  validateToken(token: any) {
    return this.http.post<any>('/api/authentication/verify?token='+token,null);
  }

  sendPasswordEmail(email) {
    return this.http.post<any>('/api/authentication/reset?email='+email,null);
  }

  checkPasswordToken(token) {
    return this.http.post<any>('/api/authentication/checkPasswordToken?token='+token,null);
  }

  resetPassword(token, password) {
    let data = {
      token : token,
      password: password
    }
    return this.http.post<any>('/api/authentication/changePassword',data);
  }

  get user(): firebase.User {
    return this.afAuth.auth.currentUser;
  }

  theripstDisconnected(userId) {
    return this.http.post<any>('/api/authentication/theripstDisconnected/'+userId,null);
  }

  theripstConnected(userId) {
    return this.http.post<any>('/api/authentication/theripstConnected/'+userId,null);
  }
}
