<h1 mat-dialog-title>{{'EVENT.ADD_PARTICIPANT' | translate}}</h1>

<div class="content-container" mat-dialog-content>
  <br>
    <div
    [formGroup]="formGroup"
      class="participant-row"
    >
    <mat-form-field class="event-location-input" style="width: 17% !important;">
      <mat-label>{{'EVENT.FIRST_NAME' | translate}}</mat-label>
      <input name="firstName"
      formControlName="firstName"
       matInput>
    </mat-form-field>

    <mat-form-field class="event-location-input" style="width: 17% !important;">
      <mat-label>{{'EVENT.FAMILY_NAME' | translate}}</mat-label>
      <input name="familyName"
      formControlName="familyName"
       matInput>
    </mat-form-field>

    <mat-form-field class="event-location-input">
      <mat-label>{{'EVENT.EMAIL' | translate}}</mat-label>
      <input name="email"
      formControlName="email"
       matInput>
    </mat-form-field>

    <mat-form-field class="event-location-input">
      <mat-label>{{'EVENT.PHONE_NUMBER' | translate}}</mat-label>
      <input name="phone"
      formControlName="phone"
       matInput>
    </mat-form-field>

    </div>
    <p> </p>
  </div>


<mat-dialog-actions align="end">
  <button mat-raised-button color="warn"(click)="cancel()" >{{'EVENT.BACK_CANCEL' | translate}}</button>
  <button mat-raised-button color="primary" [disabled]="submitted" (click)="save(formGroup)">{{'EVENT.ADD_PARTICIPANT' | translate}}</button>

</mat-dialog-actions>
