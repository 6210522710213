export interface IMenuItem {
  id?: string;
  icon?: string;
  label: string;
  to: string;
  newWindow?: boolean;
  subs?: IMenuItem[];
}

const data: IMenuItem[] = [
  {
    id: 'dashboard',
    icon: 'fa fa-home',
    label: 'MENU.MAIN_SCREEN',
    to: '/app/dashboard'
  },
  {
    id: 'play-area',
    icon: 'fa fa-street-view',
    label: 'MENU.PLAY_AREA',
    to: '/app/play-area'
  },
  {
    id: 'faq',
    icon: 'iconsminds-information',
    label: 'MENU.FAQ',
    to: '/app/welcome'
  },
  {
    id: 'events',
    icon: 'fa fa-calendar',
    label: 'MENU.MY_EVENTS',
    to: '/app/manage-events-client'
  },
  {
    id: 'programmes',
    icon: 'fa fa-calendar',
    label: 'MENU.PROGRAMEES',
    to: '/app/program'
  },
  {
    id: 'profile',
    icon: 'simple-icon-user-following',
    label: 'MENU.PROFILE',
    to: '/app/profile'
  },
  {
    id: 'template',
    icon: 'iconsminds-gear',
    label: 'MENU.TEMPLATE',
    to: '/app/template'
  }/*, 
   {
    id: 'second-menu',
    icon: 'iconsminds-three-arrow-fork',
    label: 'menu.second-menu',
    to: '/app/second-menu',
    subs: [
      {
        icon: 'simple-icon-paper-plane',
        label: 'menu.second',
        to: '/app/second-menu/second'
      }
    ]
  },*/
  /* {
    id: 'video-group',
    icon: 'iconsminds-video',
    label: 'Group Call',
    to: '/app/video-group'
  } *//*,
  {
    id: 'docs',
    icon: 'iconsminds-library',
    label: 'menu.docs',
    to: 'https://vien-docs.coloredstrategies.com/',
    newWindow: true
  } */
];
export default data;
