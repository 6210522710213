import { Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import {FormArray, FormControl, FormGroup, NgForm, Validators} from '@angular/forms';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { el } from 'date-fns/locale';
import { EmailSendingService } from 'src/app/shared/services/email-sending.service';
import { EventService } from 'src/app/shared/services/event.service';
import { IEvent, ParticipantInfo } from 'src/app/views/app/programmes/programmes.component';

export interface IFeedback {
  text: string;
  username: string;
  email: string;
}

@Component({
  selector: 'app-dialog-content',
  templateUrl: './dialog-content.component.html',
  styleUrls: ['./dialog-content.component.scss']
})
export class DialogContentComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
}

@Component({
  selector: 'app-dialog-finalize-event',
  templateUrl: './finalize-event-creation/dialog-finalize-event.component.html',
  styleUrls: ['./finalize-event-creation/dialog-finalize-event.component.scss']
})
export class DialogContentFinalizeEventComponent implements OnInit {

  sbEvent: IEvent;
  formatedDates: string[];
  saving = false;


  constructor(@Inject(MAT_DIALOG_DATA) data, private eventService: EventService, public dialogRef: MatDialogRef<DialogContentFinalizeEventComponent>) {
    this.sbEvent = data.event;
    this.formatedDates = data.event.formatedDates;
   }

  ngOnInit(): void {
    console.log(this.sbEvent);
  }

  save() {
    this.saving = true;
    let event:any = {};
    event.id = this.sbEvent.id;
    event.currency = this.sbEvent.currency;
    event.type = this.sbEvent.type== "Group Event" ? "GROUP" : "ONE_ON_ONE";
    event.isOnline = this.sbEvent.eventLocationRadio=="Online Event";
    event.address = this.sbEvent.eventLocation;
    event.dates = this.sbEvent.dates;
    event.currentParticipantsInfo = this.sbEvent.currentParticipantsInfo;
    event.currentParticipantsNumber = this.sbEvent.currentParticipantsNumber;
    event.description = this.sbEvent.eventDetails;
    event.eventLocation = this.sbEvent.eventLocation;
    event.eventName = this.sbEvent.eventName;
    event.maximumParticipant = this.sbEvent.numberOfParticipants;
    event.price = this.sbEvent.price;
    event.repetitions = this.sbEvent.recurringDates ? [this.sbEvent.recurringDates] : [];
    event.startTime = this.appendZero(this.sbEvent.startTime.hour)+":"+this.appendZero(this.sbEvent.startTime.minute);
    event.endTime = this.appendZero(this.sbEvent.endTime.hour)+":"+this.appendZero(this.sbEvent.endTime.minute);
    event.files = this.sbEvent.files;
    
    console.log(event);
    this.eventService.saveEvent(event).subscribe((data) => {
      console.log(data);
      if(event.files) {
        console.log(event.files);
        this.eventService.uploadImages(data.id, event.files[0]).subscribe((data) => {
        });
      }
      console.log("saved.....");
      this.dialogRef.close();
      this.saving = false;
    });
  }

  appendZero(data) {
    return (+data<10?'0':'')+data;
  }
}

@Component({
  selector: 'app-event-edit',
  templateUrl: './event-edit/dialog-event-edit.component.html',
  styleUrls: ['./event-edit/dialog-event-edit.component.scss']
})
export class DialogEventEditComponent implements OnInit {

  event: any;
  formatedDates: string[];
  saving = false;


  constructor(@Inject(MAT_DIALOG_DATA) data, private eventService: EventService, public dialogRef: MatDialogRef<DialogContentFinalizeEventComponent>) {
    this.event = data.event;
    this.event.numberOfParticipants = this.event.maximumParticipant;
    this.event.eventDetails = this.event.description;
    this.event.eventLocationRadio = this.event.isOnline ? 'Online Event' : 'Offline Event';
    let stime = this.event.startTime.split(":");
    this.event.startTime = {hour: +stime[0], minute: +stime[1], second: 0};
    let etime = this.event.endTime.split(":");
    this.event.endTime = {hour: +etime[0], minute: +etime[1], second: 0};
    this.formatedDates = data.event.formatedDates;
   }

  ngOnInit(): void {
    console.log(this.event);
  }
}

@Component({
  selector: 'app-dialog-content-time-expired',
  templateUrl: 'dialog-content-time-expired.html',
  styleUrls: ['./dialog-content.component.scss']
})
export class DialogContentTimeExpired implements OnInit {

  messageSent = false;
  firstName = "";

  constructor(private emailSendingService: EmailSendingService) {

  }
  ngOnInit(): void {
    this.messageSent = false;
    let user = JSON.parse(localStorage.getItem('user'));
    this.firstName = user.firstName;
  }

  onSubmit(form: NgForm) {
    const email = JSON.parse(localStorage.getItem('user')).email;
    const username = JSON.parse(localStorage.getItem('user')).firstName + ' ' + JSON.parse(localStorage.getItem('user')).lastName;
    const text = form.value.reason;
    const feedback: IFeedback = {email, username, text};

    this.emailSendingService.sendFeedbackEmail(feedback).subscribe(() => {
      this.messageSent = true;
    });
  }
}

@Component({
  selector: 'app-dialog-book-event',
  templateUrl: './book-event/dialog-book-event.component.html',
  styleUrls: ['./book-event/dialog-book-event.component.scss']
})
export class DialogBookEventComponent implements OnInit {

  sbEvent: IEvent;
  formatedDates: string[];
  submitted = false;

  contactForm = new FormGroup({
    email: new FormControl('', Validators.required),
    message: new FormControl('', Validators.required),
  })

  constructor(@Inject(MAT_DIALOG_DATA) data, private dialog: MatDialog, public dialogRef: MatDialogRef<DialogBookEventComponent>, private eventService: EventService) {
    this.sbEvent = data.event;
    this.formatedDates = data.event.formatedDates;

   }

   dialogConfig = new MatDialogConfig();

  ngOnInit(): void {
    console.log(this.sbEvent);
  }

  finalizeBooking() {
    this.dialogRef.close(true);
  }

  sendMessage() {
    this.submitted = true;
    let therapist = JSON.parse(localStorage.getItem('therapist'));
    let data = { email: this.contactForm.get('email').value, message: this.contactForm.get('message').value , firstName: therapist.firstName, lastName: therapist.lastName}
    this.eventService.contactToTherapist(data).subscribe(() => {
      this.submitted = false;
      this.contactForm.reset();
    });
  }
}

@Component({
  selector: 'app-dialog-cancel-event',
  templateUrl: './cancel-event-therapist/dialog-cancel-event.component.html',
  styleUrls: ['./cancel-event-therapist/dialog-cancel-event.component.scss']
})
export class DialogCancelEventComponent implements OnInit {

  sbEvent: IEvent;
  formatedDates: string[];

  messageSent = false;

  constructor(@Inject(MAT_DIALOG_DATA) data, private dialog: MatDialog, private eventService: EventService) {
    this.sbEvent = data.event;
    this.formatedDates = data.event.formatedDates;

   }

   dialogConfig = new MatDialogConfig();

  ngOnInit(): void {
    console.log(this.formatedDates);
  }

  cancelEvent(form: NgForm) {
    const email = JSON.parse(localStorage.getItem('user')).email;
    const username = JSON.parse(localStorage.getItem('user')).firstName + ' ' + JSON.parse(localStorage.getItem('user')).lastName;
    const text = form.value.reason;
    const feedback: IFeedback = {email, username, text};

    let event:any = {};
    event.eventId = this.sbEvent.id;
    event.reason = text;

    console.log(event);
    this.messageSent = true;
    this.eventService.cancelEvent(event).subscribe(() => {
      console.log("cancelled.....");
      this.eventService.deleteEvent(event.eventId, false).subscribe(() => {
        this.dialog.closeAll();
      });
    });
  }
}

@Component({
  selector: 'app-dialog-cancel-booking',
  templateUrl: './cancel-booking-therapist/cancel-booking-therapist.component.html',
  styleUrls: ['./cancel-booking-therapist/cancel-booking-therapist.component.scss']
})
export class DialogCancelBookingComponent implements OnInit {

  sbEvent: IEvent;
  formatedDates: string[];

  messageSent = false;

  constructor(@Inject(MAT_DIALOG_DATA) data, private dialog: MatDialog, private eventService: EventService) {
    this.sbEvent = data.event;
    this.formatedDates = data.event.formatedDates;

   }

   dialogConfig = new MatDialogConfig();

  ngOnInit(): void {
    console.log(this.sbEvent);
  }

  cancelEvent(form: NgForm) {
    const email = JSON.parse(localStorage.getItem('user')).email;
    const username = JSON.parse(localStorage.getItem('user')).firstName + ' ' + JSON.parse(localStorage.getItem('user')).lastName;
    const text = form.value.reason;
    const feedback: IFeedback = {email, username, text};

    let event:any = {};
    event.eventId = this.sbEvent.id;
    event.reason = text;

    console.log(event);
    this.messageSent = true;
    this.eventService.cancelEvent(event).subscribe(() => {
      console.log("cancelled.....");
      this.eventService.deleteEvent(event.eventId, false).subscribe(() => {
        this.dialog.closeAll();
      });
    });
  }
}

@Component({
  selector: 'app-dialog-participants-list',
  templateUrl: './participant-list/dialog-participants-list.component.html',
  styleUrls: ['./participant-list/dialog-participants-list.component.scss']
})
export class DialogParticipantsListComponent implements OnInit {

  sbEvent: IEvent;
  formatedDates: string[];

  dataSource;
  doubleApproval = false;
  participants = [];

  displayedColumns: string[] = ['actions', 'name', 'email', 'phone', 'registrationDate'];

  messageSent = false;

  constructor(@Inject(MAT_DIALOG_DATA) data, private dialog: MatDialog, private eventService: EventService) {
    this.sbEvent = data.event;
    this.formatedDates = data.event.formatedDates;
    this.dataSource = new MatTableDataSource(this.sbEvent.currentParticipantsInfo);
    console.log(this.dataSource)
   }

   dialogConfig = new MatDialogConfig();

  ngOnInit(): void {
    console.log(this.formatedDates);
    this.getParticipants();
  }

  getParticipants() {
    this.participants = [];
    this.eventService.getEventById(this.sbEvent.id).subscribe((data) => {
      console.log(data);
      data.forEach(event => {
        event.participant.registrationDate = new Date(event.bookingDate);
        this.participants.push(event.participant);
      });
      this.dataSource = new MatTableDataSource(this.participants);
    });
  }

  deleteFirst(element) {
    if(element.doubleApproval) {
      this.eventService.deleteUserFromEvent(this.sbEvent.id, element).subscribe((data) => {
        console.log(data);
        this.getParticipants();
        element.doubleApproval = false;
      });
    } else {
      element.doubleApproval = true;
    }
  }

  cancelDelete(element) {
    element.doubleApproval = false;
    console.log(this.doubleApproval)
  }

  addParticipants() {
    this.dialog.closeAll();
    this.dialogConfig.data = {event: this.sbEvent};
    this.dialog.open(DialogAddParticipantsGroupComponent, this.dialogConfig);
  }
}

@Component({
  selector: 'app-dialog-add-participants-group',
  templateUrl: './add-participant-group/add-participant-group.component.html',
  styleUrls: ['./add-participant-group/add-participant-group.component.scss']
})
export class DialogAddParticipantsGroupComponent implements OnInit {

  sbEvent: IEvent;
  formatedDates: string[];
  submitted = false;

  formArray: FormArray = new FormArray([new FormGroup({
    firstName: new FormControl('', Validators.required),
    familyName: new FormControl('', Validators.required),
    email: new FormControl('', Validators.required),
    phone: new FormControl(''),
  })]);

  constructor(@Inject(MAT_DIALOG_DATA) data, private dialog: MatDialog, private eventService: EventService) {
    this.sbEvent = data.event;
    this.formatedDates = data.event.formatedDates;
   }

   dialogConfig = new MatDialogConfig();

  ngOnInit(): void {
    console.log(this.sbEvent);
  }

  getFormGroup(data: ParticipantInfo) {
    data = data || ({} as ParticipantInfo);
    return new FormGroup({
      firstName: new FormControl(data.name, Validators.required),
      familyName: new FormControl(data.familyName, Validators.required),
      email: new FormControl(data.email, Validators.required),
      phone: new FormControl(data.phone),
    });
  }

  save(formArray: FormArray) {
    if (formArray.valid) {
      this.submitted = true;
      console.log(formArray.value);
      formArray.value.forEach((val, i) => {
        let user:any = {};
        user.firstName = val.firstName;
        user.lastName = val.familyName;
        user.email = val.email;
        user.phoneNumber = val.phone;
        this.eventService.addUserToEvent(this.sbEvent.id, user).subscribe((data) => {
          if(i==formArray.value.length-1) {
            this.cancel();
            this.submitted = false;
            this.eventService.refreshEventList.next(new Date());
          }
        });
      });
    } else {
      formArray.markAllAsTouched();
    }

  }

  cancel() {
    this.dialog.closeAll();
    this.dialogConfig.data = {event: this.sbEvent};
    this.dialog.open(DialogParticipantsListComponent, this.dialogConfig);
  }

}

@Component({
  selector: 'app-dialog-add-participants-group-singular',
  templateUrl: './add-participant-singular/add-participant-group-singular.component.html',
  styleUrls: ['./add-participant-singular/add-participant-group-singular.component.scss']
})
export class DialogAddParticipantsGroupSingularComponent implements OnInit {

  sbEvent: IEvent;
  formatedDates: string[];
  submitted = false;

  formGroup: FormGroup = new FormGroup({
    firstName: new FormControl('', Validators.required),
    familyName: new FormControl('', Validators.required),
    email: new FormControl('', Validators.required),
    phone: new FormControl(''),
  });

  constructor(@Inject(MAT_DIALOG_DATA) data, private dialog: MatDialog, private eventService: EventService) {
    this.sbEvent = data.event;
    this.formatedDates = data.event.formatedDates;
   }

   dialogConfig = new MatDialogConfig();

  ngOnInit(): void {
  }

  getFormGroup(data: ParticipantInfo) {
    data = data || ({} as ParticipantInfo);
    return new FormGroup({
      firstName: new FormControl(data.name, Validators.required),
      familyName: new FormControl(data.familyName, Validators.required),
      email: new FormControl(data.email, Validators.required),
      phone: new FormControl(data.phone),
    });
  }

  save(formGroup: FormGroup) {
    if (formGroup.valid) {
      this.submitted = true;
      console.log(formGroup.value);
      let user:any = formGroup.value;
      user.lastName = user.familyName;
      user.phoneNumber = user.phone;
      this.eventService.addUserToEvent(this.sbEvent.id, user).subscribe((data) => {
          this.cancel();
          this.submitted = true;
      });
    } else {
      formGroup.markAllAsTouched();
    }

  }

  cancel() {
    this.dialog.closeAll();
  }

}

@Component({
  selector: 'app-dialog-finalize-booking',
  templateUrl: './finalize-booking/dialog-finalize-booking.component.html',
  styleUrls: ['./finalize-booking/dialog-finalize-booking.component.scss']
})
export class DialogFinalizeBookingComponent implements OnInit {

  submitted = false;
  sbEvent: IEvent;
  formatedDates: string[];
  formGroup = new FormGroup({
    firstName: new FormControl('', Validators.required),
    familyName: new FormControl('', Validators.required),
    email: new FormControl('', Validators.required),
    phone: new FormControl('', Validators.required),
  });

  constructor(@Inject(MAT_DIALOG_DATA) data, private dialog: MatDialog, private eventService: EventService, public dialogRef: MatDialogRef<DialogFinalizeBookingComponent>) {
    this.sbEvent = data.event;
    this.formatedDates = data.event.formatedDates;

   }

  ngOnInit(): void {
    console.log(this.sbEvent);
  }

  save(form: FormGroup) {
    this.submitted = true;
    console.log(form.value);
    let val = form.value;
    let user:any = {};
    user.firstName = val.firstName;
    user.lastName = val.familyName;
    user.email = val.email;
    user.phoneNumber = val.phone;
    this.eventService.saveUserEvent(this.sbEvent.id, user).subscribe((data) => {
      this.dialogRef.close(true);
    });
  }

}

@Component({
  selector: 'app-dialog-content-four-hours',
  templateUrl: './dialog-content-four-hours.html',
  styleUrls: ['./dialog-content.component.scss']
})
export class DialogContentFourHours implements OnInit {

  messageSent = false;
  firstName = "";

  constructor(private emailSendingService: EmailSendingService) {

  }
  ngOnInit(): void {
    this.messageSent = false;
    let user = JSON.parse(localStorage.getItem('user'));
    this.firstName = user.firstName;
  }

  onSubmit(form: NgForm) {
    const email = JSON.parse(localStorage.getItem('user')).email;
    const username = JSON.parse(localStorage.getItem('user')).firstName + ' ' + JSON.parse(localStorage.getItem('user')).lastName;
    const text = form.value.reason;
    const feedback: IFeedback = {email, username, text};

    this.emailSendingService.sendFeedbackEmail(feedback).subscribe(() => {
      this.messageSent = true;
    } );
  }
}
