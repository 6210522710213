import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app.routing';
import { AppComponent } from './app.component';
import { ViewsModule } from './views/views.module';
import { TranslateModule } from '@ngx-translate/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AngularFireModule } from '@angular/fire';
import { environment } from '../environments/environment';
import { LayoutContainersModule } from './containers/layout/layout.containers.module';
import { StompConfig, StompService } from '@stomp/ng2-stompjs';
import { TokenInterceptor } from './interceptor/token.interceptor';
import { AuthGuard } from './views/user/auth.guard';
import { TherapistInterceptor } from './interceptor/therapist.interceptor';
import { ModalModule } from 'ngx-bootstrap/modal';

const WEBSOCKET_URL = (window.location.port == '4400' ? window.location.hostname+':9000' : window.location.host);
const WEBSOCKET_PATH = (window.location.hostname == 'constellationsonline.eu' || window.location.hostname == 'www.constellationsonline.eu' || window.location.hostname == 'dev.constellationsonline.eu' || window.location.hostname == 'server4.constellationsonline.eu' ? '/api/socket/websocket' : '/socket/websocket')
const WEBSOCKET_PROTOCOL = (window.location.protocol == 'https:' ? 'wss' : 'ws');

const stompConfig: StompConfig = {
    url: WEBSOCKET_PROTOCOL+'://' + WEBSOCKET_URL + WEBSOCKET_PATH,
    headers: {
        login: 'guest',
        passcode: 'guest'
    },
    heartbeat_in: 0,
    heartbeat_out: 20000,
    reconnect_delay: 5000,
    debug: true
};

@NgModule({
  imports: [
    BrowserModule,
    ViewsModule,
    AppRoutingModule,
    LayoutContainersModule,
    BrowserAnimationsModule,
    TranslateModule.forRoot(),
    HttpClientModule,
    ModalModule.forRoot(),
    AngularFireModule.initializeApp(environment.firebase)
  ],
  declarations: [
    AppComponent
  ],
  providers: [
    AuthGuard,
    StompService,
    {
        provide: StompConfig,
        useValue: stompConfig
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TherapistInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
