<h1 mat-dialog-title>{{'EVENT.FINALIZE_BOOKING' | translate}}</h1>

<div class="content-container" mat-dialog-content>
  <img
    class="picture"
    [src]="sbEvent.thumbnail"
    height="200"
    *ngIf="sbEvent.thumbnail"
  />

  <br />
  <p>{{'EVENT.EVENT_NAME' | translate}}: {{ sbEvent.eventName }}</p>

  <br />

  <form [formGroup]="formGroup">
      <input
        placeholder="{{'EVENT.FIRST_NAME' | translate}}"
        name="firstName"
        formControlName="firstName"
        class="form-control"
        matInput
        required
      />
      <br>

      <input
        placeholder="{{'EVENT.FAMILY_NAME' | translate}}"
        name="familyName"
        formControlName="familyName"
        class="form-control"
        matInput
        required
      />
      <br>
      <input
        placeholder="{{'EVENT.EMAIL' | translate}}"
        name="email"
        formControlName="email"
        class="form-control"
        matInput
        required

      />
      <p>{{'EVENT.SENT_CONFIRM_EMAIL' | translate}}</p>
      <input
        placeholder="{{'EVENT.PHONE_NUMBER' | translate}}"
        name="phone"
        formControlName="phone"
        class="form-control"
        matInput
      />
      <p>{{'EVENT.SHARED_WITH_THERAPIST' | translate}}</p>

      <button [disabled]="!formGroup.valid || submitted" type="submit" (click)="save(formGroup)" class="btn btn-primary submit-button">{{'EVENT.FINALIZE_BOOKING' | translate}}</button>

    </form>

</div>
