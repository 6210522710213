import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EventService {

  public refreshEventList = new Subject<any>();

  constructor(private http : HttpClient) {
  }

  getRefreshEventList(): Observable<any> {
    return this.refreshEventList.asObservable();
  }

  saveEvent(event) {
    return this.http.post<any>("/api/event/save",event);
  }

  getUpcomingEvent(therapistName, type, startDate, endDate, coding, dates, pageData, infiniteFuture) {
    let data = {type: type, startDate: startDate, endDate: endDate, keyword: coding, dates: dates, infiniteFuture: infiniteFuture};
    return this.http.post<any>("/api/event/upcoming/"+therapistName+"?page="+pageData.page+"&size="+pageData.size, data);
  }

  getUpcomingEventwithData(therapistName, pageData, data) {
    return this.http.post<any>("/api/event/upcoming/"+therapistName+"?page="+pageData.page+"&size="+pageData.size, data);
  }

  cancelEvent(event) {
    return this.http.post<any>("/api/event/cancel",event);
  }

  deleteEvent(id, repetation) {
    return this.http.delete<any>("/api/event/delete/"+id+"?deleteRepetitions="+repetation);
  }

  uploadImages(eventId, file) {
    const formData: FormData = new FormData();
    formData.append('file', file);
    return this.http.post<any>("/api/event/images/"+eventId+"/upload",formData);
  }

  addUserToEvent(eventId, user) {
    return this.http.post<any>("/api/event-booking/user/"+eventId+"/add",user);
  }

  saveUserEvent(eventId, user) {
    return this.http.post<any>("/api/event-booking/user/"+eventId,user);
  }

  getEventById(eventId) {
    return this.http.get<any>("/api/event-booking/"+eventId);
  }

  getImageByEventId(eventId, fileName) {
    const requestOptions: Object = {
      /* other options here */
      responseType: 'blob'
    }
    return this.http.get<any>("/api/event/image/"+eventId+"/"+fileName, requestOptions);
  }

  getEventAddressByTheripstId(theripstId) {
    return this.http.get<any>("/api/event/findDistinctAddressByTheripstId/"+theripstId);
  }

  getEventNameByTheripstId(theripstId) {
    return this.http.get<any>("/api/event/findDistinctNameByTheripstId/"+theripstId);
  }

  deleteUserFromEvent(eventId, user) {
    return this.http.post<any>("/api/event-booking/user/"+eventId+"/delete", user);
  }

  confirmEventBooking(token) {
    return this.http.get<any>("/api/event-booking/confirm?token="+token);
  }

  declineEventBooking(token, data) {
    return this.http.post<any>("/api/event-booking/decline?token="+token, data);
  }

  getUserImages() {
    return this.http.get<any>("/api/event/images");
  }

  getLatestEventByTherapistId(theripstId) {
    return this.http.get<any>("/api/event/findLatestByTherapistId/"+theripstId);
  }

  contactToTherapist(data) {
    return this.http.post<any>("/api/support/contact", data);
  }
}
