<h1 mat-dialog-title>{{'EVENT.CANCEL_EVENT' | translate}}</h1>

<div class="content-container" mat-dialog-content>

  <p>{{'EVENT.CANCEL_EVENT_CONFIRM' | translate}}</p>

  <br>

  <p>{{'EVENT.EVENT_DATE' | translate}}: {{ sbEvent?.dates?.length>0 ? sbEvent.dates : '' }}</p>

  <p>{{'EVENT.REPETITION' | translate}}: {{ sbEvent?.dates > 1? ('EVENT.YES' | translate): ('EVENT.NO' | translate) }}</p>

  <p>{{'EVENT.PARTICIPANTS_RECEIVE_CANCEL_EMAIL' | translate}}</p>

  <form #form="ngForm" (ngSubmit)="cancelEvent(form)">
    <label for="reason-textarea">{{'EVENT.CANCEL_REASON' | translate}}:</label>
      <textarea name="reason" id="reason-textarea" ngModel class="form-control" required></textarea>
      <button [disabled]="!form.valid || messageSent" id="reasons-button" type="submit" class="btn btn-primary">{{'EVENT.OK' | translate}}</button>
      <div *ngIf="messageSent">{{'EVENT.THANK_YOU_REPLY' | translate}}!</div>
  </form>
  <br>
  <p>{{'EVENT.CLICK_OUTSIDE' | translate}}</p>

</div>
