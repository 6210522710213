<div id="content-container" mat-dialog-content>
    <span id="logo-center" class="logo-single"></span>

    <p>{{'NOTIFY_DIALOG.FIVE_HR_DIALOG_TXT_1' | translate}} {{firstName}}</p>
    <p>{{'NOTIFY_DIALOG.FIVE_HR_DIALOG_TXT_2' | translate}}</p>
    <p>{{'NOTIFY_DIALOG.FIVE_HR_DIALOG_TXT_3' | translate}}</p>
    <p>{{'NOTIFY_DIALOG.FIVE_HR_DIALOG_TXT_4' | translate}}</p>
    <button [mat-dialog-close] routerLink="/app/subscription/professional-package" id="plans-button" class="btn btn-primary">{{'NOTIFY_DIALOG.FIVE_HR_DIALOG_BTN' | translate}}</button>
    <form #form="ngForm" (ngSubmit)="onSubmit(form)">
      <label for="reason-textarea">{{'NOTIFY_DIALOG.FIVE_HR_DIALOG_FEEDBACK' | translate}}</label> 
        <textarea name="reason" id="reason-textarea" ngModel class="form-control" required></textarea>
        <button [disabled]="!form.valid || messageSent" id="reasons-button" type="submit" class="btn btn-primary">{{'NOTIFY_DIALOG.FIVE_HR_DIALOG_SEND' | translate}}</button>
        <div *ngIf="messageSent">Thank you for your reply!</div>
      </form>

</div>

