import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';

import en from '../../lang/en.json';
import hu from '../../lang/hu.json';
import ro from '../../lang/ro.json';
import de from '../../lang/de.json';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';

const languageKey = 'sb-lang';

@Injectable({
  providedIn: 'root',
})
export class LangService {
  isSingleLang = false;
  renderer: Renderer2;
  defaultLanguage = localStorage.getItem(languageKey) || 'en';
  supportedLanguages: Language[] = [
    { code: 'en', direction: 'ltr', label: 'English', shorthand: 'en' },
    { code: 'de', direction: 'ltr', label: 'Deutsche', shorthand: 'de' },
    { code: 'hu', direction: 'ltr', label: 'Magyar', shorthand: 'hu' },
    { code: 'ro', direction: 'ltr', label: 'Română', shorthand: 'ro' }
  ];

  constructor(private translate: TranslateService, private rendererFactory: RendererFactory2, private router: Router, private httpClient : HttpClient) {
    this.renderer = this.rendererFactory.createRenderer(null, null);
  }

  init() {
    this.translate.setTranslation('en', en);
    this.translate.setTranslation('hu', hu);
    this.translate.setTranslation('ro', ro);
    this.translate.setTranslation('de', de);
    this.translate.setDefaultLang(this.defaultLanguage);
    if (this.isSingleLang) {
      this.translate.use(this.defaultLanguage);
    } else {
      this.language = '';
    }
  }

  checkForDirectionChange() {
    this.renderer.removeClass(document.body, 'ltr');
    this.renderer.removeClass(document.body, 'rtl');
    this.renderer.addClass(document.body, this.direction);
    this.renderer.setAttribute(document.documentElement, 'direction', this.direction);
  }

  set language(lang: string) {
    let language = lang || localStorage.getItem(languageKey);
    const isSupportedLanguage = this.supportedLanguages.map(item => item.code).includes(language);
    if (!isSupportedLanguage) {
      language = this.defaultLanguage;
    }

    if (lang !== '' && this.supportedLanguages.map(item => item.code).includes(lang) && this.direction !== this.supportedLanguages.find(item => item.code === lang).direction) {
    } else {
      this.translate.use(language);
      localStorage.setItem(languageKey, language);
      if(localStorage.getItem('user')) {
        let user = JSON.parse(localStorage.getItem('user'));
        let data = {
          userId : user.id,
          languageCode: language
        };
        this.httpClient.post<any>("/api/updateLanguage", data).subscribe(data => {});
      }
    }
    setTimeout(() => {
      console.log(language);
      const logoSingles = document.getElementsByClassName("logo-single");
      for (let i = 0; i < logoSingles.length; i++) {
        console.log(logoSingles[i]);
        logoSingles[i]["style"].background="url('/assets/img/"+(language=='hu'?"constellations_logo_hu":"constellations_logo_en")+".png') no-repeat";
      }
      const logos = document.getElementsByClassName("logo");
      for (let i = 0; i < logos.length; i++) {
        console.log(logos[i]);
        logos[i]["style"].background="url('/assets/img/"+(language=='hu'?"constellations_logo_hu":"constellations_logo_en")+".png') no-repeat";
      }
    }, 300);
    this.checkForDirectionChange();
  }

  get language(): string {
    return this.translate.currentLang;
  }

  get languageShorthand(): string {
    return this.supportedLanguages.find(item => item.code === this.translate.currentLang).shorthand;
  }

  get direction(): string {
    return this.supportedLanguages.find(item => item.code === this.translate.currentLang).direction;
  }

  get languageLabel(): string {
    return this.supportedLanguages.find(item => item.code === this.translate.currentLang).label;
  }

  getUserLocation() {
    return this.httpClient.get<IGeolocation>('/api/getUserLocation');
  }
}

export interface IGeolocation {
  ip: string;
  country_name: string;
  country_code;
}

export class Language {
  code: string;
  direction: string;
  label: string;
  shorthand: string;
}
