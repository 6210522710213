<h1 mat-dialog-title>{{'EVENT.ADD_PARTICIPANTS' | translate}}</h1>

<div class="content-container" mat-dialog-content>
  <button mat-raised-button color="primary" (click)="formArray.push(getFormGroup(null))"><mat-icon>add_circle</mat-icon> {{'EVENT.ADD_PARTICIPANT' | translate}} </button>

  <br>
  <div class="" [formGroup]="formArray">
    <div
      *ngFor="let group of formArray.controls; let i = index"
      [formGroup]="group"
      class="participant-row"
    >
      <mat-form-field class="event-location-input" style="width: 17% !important;">
        <mat-label>{{'EVENT.FIRST_NAME' | translate}}</mat-label>
        <input name="firstName"
        formControlName="firstName"
        [(ngModel)]="group.firstName" matInput>
      </mat-form-field>

      <mat-form-field class="event-location-input" style="width: 17% !important;">
        <mat-label>{{'EVENT.FAMILY_NAME' | translate}}</mat-label>
        <input name="familyName"
        formControlName="familyName"
        [(ngModel)]="group.familyName" matInput>
      </mat-form-field>

      <mat-form-field class="event-location-input">
        <mat-label>{{'EVENT.EMAIL' | translate}}</mat-label>
        <input name="email"
        formControlName="email"
        [(ngModel)]="group.email" matInput>
      </mat-form-field>

      <mat-form-field class="event-location-input">
        <mat-label>{{'EVENT.PHONE_NUMBER' | translate}}</mat-label>
        <input name="phone"
        formControlName="phone"
        [(ngModel)]="group.phone" matInput>
      </mat-form-field>

      <button matSuffix mat-icon-button aria-label="Clear"  style="color:red" (click)="formArray.removeAt(i)"><mat-icon>delete</mat-icon></button>
    </div>
    <p> </p>
  </div>

</div>

<mat-dialog-actions align="end">
  <button mat-raised-button color="warn"(click)="cancel()" >{{'EVENT.BACK_CANCEL' | translate}}</button>
  <button mat-raised-button color="primary" [disabled]="submitted" (click)="save(formArray)">{{'EVENT.ADD_PARTICIPANT(S)' | translate}}</button>

</mat-dialog-actions>
