import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SocketSubscriptionService {

  public unRegisterUsers = new Subject<any>();

  public disconnected = new Subject<any>();

  public reconnected = new Subject<any>();

  public startvideo = new Subject<any>();

  public refreshadmin = new Subject<any>();

  public refreshPlayersValue = new Subject<any>();

  public logout = new Subject<any>();

  public login = new Subject<any>();

  public sessionout = new Subject<any>();

  public timerUpdate = new Subject<any>();

  public dialog = new Subject<any>();

  constructor() { }

  getUnRegisterUsers(): Observable<any> {
    return this.unRegisterUsers.asObservable();
  }

  getDisconnected(): Observable<any> {
    return this.disconnected.asObservable();
  }

  getReconnected(): Observable<any> {
    return this.reconnected.asObservable();
  }

  getStartvideo(): Observable<any> {
    return this.startvideo.asObservable();
  }

  getRefreshadmin(): Observable<any> {
    return this.refreshadmin.asObservable();
  }

  getRefreshPlayersValue(): Observable<any> {
    return this.refreshPlayersValue.asObservable();
  }

  getLogout(): Observable<any> {
    return this.logout.asObservable();
  }

  getLogin(): Observable<any> {
    return this.login.asObservable();
  }

  getSessionout(): Observable<any> {
    return this.sessionout.asObservable();
  }

  getTimerUpdate(): Observable<any> {
    return this.timerUpdate.asObservable();
  }

  getDialog(): Observable<any> {
    return this.dialog.asObservable();
  }
}
