import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Subscription, interval } from 'rxjs';
import { AuthService } from '../auth.service';

export enum LogLevel {
  All = 0,
  Debug = 1,
  Info = 2,
  Warn = 3,
  Error = 4,
  Fatal = 5,
  Off = 6
}

export class LogEntry {

  date: Date = new Date();
  message: string = "";
  level: LogLevel = LogLevel.Debug;
  info: any[] = [];
  logWithDate: boolean = true;

  buildLogString(): string {
    let ret: string = "";
    if (this.logWithDate) {
      ret = new Date() + " - ";
    }
    ret += "Type: " + LogLevel[this.level];
    ret += " - Message: " + this.message;
    if (this.info.length) {
      ret += " - Extra Info: " + this.formatParams(this.info);
    }
    return ret;
  }

  private formatParams(params: any[]): string {
    let ret: string = params.join(",");
    if (params.some(p => typeof p == "object")) {
      ret = "";
      for (let item of params) {
        ret += JSON.stringify(item) + ",";
      }
    }
    return ret;
  }
}

@Injectable({
  providedIn: 'root'
})
export class LoggerService {

  private applicationId = window.location.port == '443' ? window.location.hostname : window.location.hostname+':'+window.location.port;

  private _clockSubscription: Subscription;
  level: LogLevel = LogLevel.All;
  logWithDate: boolean = true;
  logInBrowser: boolean = true;
  logEntries: any[] = [];
  saveLogToServerEvery = 15000; // 15 sec

  constructor(private http : HttpClient, private authService: AuthService) {
    this.init();
  }

  init() {
    if(!this._clockSubscription) {
      this._clockSubscription = interval(this.saveLogToServerEvery).subscribe(val => this.writeLogToServer());
    }
  }

  destroy() {
    if(this._clockSubscription) {
      this._clockSubscription.unsubscribe;
    }
  }

  debug(msg: string, ...optionalParams: any[]) {
    this.writeToLog(msg, LogLevel.Debug, 
                    optionalParams);
  }
  
  info(msg: string, ...optionalParams: any[]) {
    this.writeToLog(msg, LogLevel.Info, 
                    optionalParams);
  }
  
  warn(msg: string, ...optionalParams: any[]) {
    this.writeToLog(msg, LogLevel.Warn, 
                    optionalParams);
  }
  
  error(msg: string, ...optionalParams: any[]) {
    this.writeToLog(msg, LogLevel.Error, 
                    optionalParams);
  }
  
  fatal(msg: string, ...optionalParams: any[]) {
    this.writeToLog(msg, LogLevel.Fatal, 
                    optionalParams);
  }
  
  log(msg: string, ...optionalParams: any[]) {
    this.writeToLog(msg, LogLevel.All, 
                    optionalParams);
  }

  private shouldLog(level: LogLevel): boolean {
    let ret: boolean = false;
    if ((level >= this.level &&
         level !== LogLevel.Off) ||
         this.level === LogLevel.All) {
      ret = true;
    }
    return ret;
  }

  private writeToLog(msg: string, level: LogLevel, params: any[]) {
    if(Array.isArray(msg)) {
      return;
    }
    if (this.shouldLog(level)) {
      let entry: LogEntry = new LogEntry();
      entry.message = msg;
      entry.level = level;
      entry.info = params;
      entry.logWithDate = this.logWithDate;
      this.logEntries.push(entry);
      if(this.logInBrowser) {
        console.log(entry.buildLogString());
      }
    }
  }

  private writeLogToServer() {
    let length = this.logEntries.length;
    console.log("log data length: "+length);
    if(length>0) {
      this.saveLogToServer().subscribe(data => {
        this.logEntries.splice(0, length);
        console.log("saving logs....");
      }, error => {
        this.logEntries.splice(0, length);
      });
    }
  }

  public saveLogToServer() {
    let user = JSON.parse(localStorage.getItem('user'));
    let client = JSON.parse(localStorage.getItem('client'));
    let req = {applicationId: this.applicationId, userDetails: (client!=null?(client.therapist.id+"/"+client.id):(user.id+"/"+user.firstName+"_"+user.lastName)), loggerEntries: this.logEntries};
    return this.http.post<any>("/logger_api/logger/log/save",req);
  }

  public getLogs(date ,theripstId ,fileName) {
    return this.http.get<any>("/logger_api/logger/logs/"+date+"/"+theripstId+"/"+fileName);
  }

  public getMyIp() {
    return this.http.get<any>("/logger_api/logger/getMyIp");
  }

  public bugReportSave(data, img) {
    let user = JSON.parse(localStorage.getItem('user'));
    let client = JSON.parse(localStorage.getItem('client'));
    data.applicationId = this.applicationId;
    data.userDetails = (client!=null?(client.therapist.id+"/"+client.id):(user.id+"/"+user.firstName+"_"+user.lastName));
    data.name = (client!=null?client.name:user.firstName+" "+user.lastName);
    const formData: FormData = new FormData();
    formData.append('appId', this.applicationId);
    formData.append('name', data.name);
    formData.append('priority', data.priority);
    formData.append('comment', data.comment);
    formData.append('userDetails', data.userDetails);
    formData.append('noImg', (img==null)+"");
    formData.append('browser', this.getBrowserDetails());
    formData.append('os', this.getDeviceOS());
    formData.append('screenSize', this.getScreenSize());
    formData.append('viewSize', this.getViewSize()+" Zoom: "+((window.outerWidth / window.innerWidth)*100)+"% Device Pixel Ratio: "+(window.devicePixelRatio));
    if(img) {
      formData.append('file', img, 'scr.jpeg');
    }
    console.log(data);
    return this.http.post<any>("/logger_api/logger/bug/report/save",formData);
  }

  private getTherapistUserId() {
    let user = JSON.parse(localStorage.getItem('user'));
    if(user && user.roles && user.roles[0] == 'ROLE_THERAPIST') {
      return user.id;
    }
    return null;
  }

  public getBrowserDetails() {
    var nVer = navigator.appVersion;
    var nAgt = navigator.userAgent;
    var browserName  = navigator.appName;
    var fullVersion  = ''+parseFloat(navigator.appVersion); 
    var majorVersion = parseInt(navigator.appVersion,10);
    var nameOffset,verOffset,ix;

    // In Opera, the true version is after "Opera" or after "Version"
    if ((verOffset=nAgt.indexOf("Opera"))!=-1) {
    browserName = "Opera";
    fullVersion = nAgt.substring(verOffset+6);
    if ((verOffset=nAgt.indexOf("Version"))!=-1) 
      fullVersion = nAgt.substring(verOffset+8);
    }
    // In MSIE, the true version is after "MSIE" in userAgent
    else if ((verOffset=nAgt.indexOf("MSIE"))!=-1) {
    browserName = "Microsoft Internet Explorer";
    fullVersion = nAgt.substring(verOffset+5);
    }
    // In Chrome, the true version is after "Chrome" 
    else if ((verOffset=nAgt.indexOf("Chrome"))!=-1) {
    browserName = "Chrome";
    fullVersion = nAgt.substring(verOffset+7);
    }
    // In Safari, the true version is after "Safari" or after "Version" 
    else if ((verOffset=nAgt.indexOf("Safari"))!=-1) {
    browserName = "Safari";
    fullVersion = nAgt.substring(verOffset+7);
    if ((verOffset=nAgt.indexOf("Version"))!=-1) 
      fullVersion = nAgt.substring(verOffset+8);
    }
    // In Firefox, the true version is after "Firefox" 
    else if ((verOffset=nAgt.indexOf("Firefox"))!=-1) {
    browserName = "Firefox";
    fullVersion = nAgt.substring(verOffset+8);
    }
    // In most other browsers, "name/version" is at the end of userAgent 
    else if ( (nameOffset=nAgt.lastIndexOf(' ')+1) < 
              (verOffset=nAgt.lastIndexOf('/')) ) 
    {
    browserName = nAgt.substring(nameOffset,verOffset);
    fullVersion = nAgt.substring(verOffset+1);
    if (browserName.toLowerCase()==browserName.toUpperCase()) {
      browserName = navigator.appName;
    }
    }
    // trim the fullVersion string at semicolon/space if present
    if ((ix=fullVersion.indexOf(";"))!=-1)
      fullVersion=fullVersion.substring(0,ix);
    if ((ix=fullVersion.indexOf(" "))!=-1)
      fullVersion=fullVersion.substring(0,ix);

    majorVersion = parseInt(''+fullVersion,10);
    if (isNaN(majorVersion)) {
    fullVersion  = ''+parseFloat(navigator.appVersion); 
    majorVersion = parseInt(navigator.appVersion,10);
    }

    return (''
    +'Browser name = '+browserName+', '
    +'Full version = '+fullVersion+', '
    +'Major version = '+majorVersion+', '
    +'navigator.appName = '+navigator.appName+', '
    +'navigator.userAgent = '+navigator.userAgent
    )
  }

  public getDeviceOS() {
    var OSName="Unknown OS";
    if (navigator.appVersion.indexOf("Win")!=-1) OSName="OS: Windows";
    if (navigator.appVersion.indexOf("Mac")!=-1) OSName="OS: MacOS";
    if (navigator.appVersion.indexOf("X11")!=-1) OSName="OS: UNIX";
    if (navigator.appVersion.indexOf("Linux")!=-1) OSName="OS: Linux";

    return OSName;
  }

  public getScreenSize() {
    return "ScreenSize: " + window.screen.width + "x" + window.screen.height;
  }

  public getViewSize() {
    return "ViewSize: " + window.innerWidth + "x" + window.innerHeight;
  }

  public logIpAddress() {
    this.getMyIp().subscribe((data) => {
      let ip : any = data.ip;
      this.log("IP Address: "+ip);
      this.saveLogToServer();
    });
  }
}
