import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CronJobService {

  public refreshEventList = new Subject<any>();

  constructor(private http : HttpClient) {
  }

  getByType(type) {
    return this.http.post<any>("/api/cronjob/findByType", type);
  }

  save(data) {
    return this.http.post<any>("/api/cronjob/save", data);
  }
}