<div class="content-container" mat-dialog-content>

  <div style="display: flex;">
    <div style="text-align: center;">
      <p class="event-name">{{ sbEvent.eventName }}</p><br>
      <img class="picture" [src]="sbEvent.thumbnail" style="height: auto;width: 300px;" *ngIf="sbEvent.thumbnail">
    </div>
    <div style="text-align: center;min-width: 300px;">
      <p style="margin-top: 70px;min-height: 100px;font-size: 18px;">{{ sbEvent.eventDetails }}</p>
      <button mat-raised-button color="basic" (click)="finalizeBooking()">{{'EVENT.MAKE_BOOKING' | translate}}</button>
      <p style="margin-top: 15px;font-size: 18px;">{{'EVENT.EVENT_PRICE' | translate}}: {{ sbEvent.price }} {{ sbEvent.currency }} </p>
    </div>
    <div style="text-align: center;">
      <p class="event-name">{{'EVENT.FREE_ASK' | translate}}</p><br>
      <div class="" [formGroup]="contactForm">
          <i style="color: #a04095;font-size: 35px;" class="fa fa-envelope"></i>
          <mat-form-field class="event-location-input" style="margin-left: 10px;">
            <mat-label>{{'EVENT.YOUR_EMAIL' | translate}}</mat-label>
            <input name="email"
            formControlName="email" matInput>
          </mat-form-field><br>
          <mat-form-field class="event-location-input" style="width: 100%;">
            <mat-label>{{'EVENT.YOUR_MESSAGE' | translate}}</mat-label>
            <textarea matInput
                  cdkTextareaAutosize
                  cdkAutosizeMinRows="5"
                  cdkAutosizeMaxRows="20"
                  name="message"
                  formControlName="message"></textarea>
          </mat-form-field>
      </div>
      <button [disabled]="!contactForm.valid || submitted" (click)="sendMessage()" class="btn btn-primary" >{{'EVENT.SEND' | translate}}</button>
    </div>
  </div>
  <br/><br/><br/><br/><br/>
  <div style="display: flex;">
    <div>
      <span style="padding: 10px;"><i style="color: #a04095;font-size: 35px;" class="fa fa-globe"></i></span>
      <span style="font-size: 18px;" *ngIf="sbEvent.eventLocationRadio == 'Offline Event'">{{'EVENT.LOCATION' | translate}}: {{ sbEvent.eventLocationRadio }} / {{ sbEvent.eventLocation }}</span>
      <span style="font-size: 18px;" *ngIf="sbEvent.eventLocationRadio == 'Online Event'">{{'EVENT.LOCATION' | translate}}: {{ sbEvent.eventLocationRadio }}</span><br>
    </div>
    <div>
      <span style="padding: 10px;"><i style="color: #a04095;font-size: 35px;" class="fa fa-calendar"></i></span>
      <span style="font-size: 18px;">{{ sbEvent.dates }}</span><br>
    </div>
    <div style="margin-top: 10px;">
      <span style="font-size: 18px;margin-left: 20px;">{{'EVENT.START_TIME' | translate}}: {{ sbEvent.startTime.hour + ':' + sbEvent.startTime.minute }}</span><br>
    </div>
    <div style="margin-top: 10px;">
      <span style="font-size: 18px;margin-left: 20px;">{{'EVENT.END_TIME' | translate}}: {{ sbEvent.endTime.hour + ':' + sbEvent.endTime.minute }}</span><br>
    </div>
  </div>

  <br>
  <p>{{'EVENT.CLICK_OUTSIDE' | translate}}</p>

</div>
